<section class="pt-3 pb-3 px-3">
    <div class="container-fluid">
        <div class="row breadcrumb-h">
            <div class="col-12">
                <div class="d-flex align-items-center justifiy-content-center">
                    <h4 class="heading"><i class="fe fe-cpu"></i> Aggregation / Parser</h4>
                    <form class="form-row ml-auto ">
                        <div class="col">
                            <div class="form-group mb-0">
                                <ng-select placeholder="Select Job Name"> </ng-select>
                              </div>
                        </div>
                        <div class="col">
                            <div class="form-group mb-0">
                                <ng-select placeholder="Select Job Status"> </ng-select>
                              </div>
                        </div>
                        <div class="col">
                            <div class="form-group mb-0">
                                <ng-select placeholder="Select All Cycles"> </ng-select>
                              </div>
                        </div>
                    </form>
                </div>
                
            </div>
        </div>
    </div>            
</section>
<section class=" px-3">
    <div class="container-fluid">
        <div class="row card-statistics">
            <div class="col-4">
                <div class="row">
                    <div class="col">
                        <div class="card mb-2">
                            <div class="card-bx">
                                <div class="statistics">
                                    <h6>Queue Date</h6>
                                    <h5 class="mb-0">21 <span class="date">Apr 22</span></h5>
                                </div>
                                <div class="statistics">
                                    <h6>Estimate Date</h6>
                                    <h5 class="mb-0">21 <span class="date">Apr 22</span></h5>
                                </div>
                                <div class="statistics">
                                    <h6>Days in Queue</h6>
                                    <h5 class="mb-0">50 <span class="date">days</span></h5>
                                </div>
             
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-bx">
                                <div class="statistics">
                                    <h6>Avg Agg per day</h6>
                                    <h5 class="mb-0">30M<span class="value-icon ml-2 value-down"><i class="fe fe-trending-down"></i></span></h5>
                                </div>
                                <div class="statistics">
                                    <h6>Queue Count</h6>
                                    <h5 class="mb-0">5<span class=" ml-2 value-text value-percentage">50%</span></h5>
                                </div>
                                <div class="statistics">
                                    <h6>Total Agg Count</h6>
                                    <h5 class="mb-0">50<span class=" ml-2 value-text value-percentage">33%</span></h5>
                                </div>
             
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-bx">
                                <div class="statistics">
                                    <h6>Total Inputs</h6>
                                    <h5 class="mb-0">50K<span class=" ml-2 value-text value-percentage">33%</span></h5>
                                </div>
                                <div class="statistics">
                                    <h6>Client</h6>
                                    <h5 class="mb-0">50K<span class=" ml-2 value-text value-percentage">16%</span></h5>
                                </div>
                                <div class="statistics">
                                    <h6>Jobs</h6>
                                    <h5 class="mb-0">50<span class="value-icon ml-2 value-up"><i class="fe fe-trending-up"></i></span></h5>
                                </div>
             
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-2 mb-2">
                    <div class="col-12">
                        <h3 class="title"><span>O</span>verdue / Reason Delayed</h3>
                    </div>
                </div>
                <div class="card ">
                    <img src="../../assets/img/graph_agg1.svg" class="img-fluid" >
                </div>
            </div>
            <div class="col-8">
                <div class="row">
                    <div class="col-12">
                        <h3 class="title"><span>A</span>ggregation / Parser</h3>
                    </div>
                </div>
                <div class="card">
                    <img src="../../assets/img/graph_agg2.svg" class="img-fluid" style="height: 589px;">
                </div>
            </div>
           
        </div>
    </div>
</section>    

<section class="px-3">
    <div class="container-fluid">
        <div class="row mt-3 mb-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex mb-2 align-items-center justifiy-content-center">
                            <h3 class="title"><span>Q</span>ueuing volume by providers</h3>
                            <div class="form-row ml-auto" >
                                <div class="d-flex queue-vol">
                                    <div class="queue">Input Count <span>150K</span> </div>
                                    <div class="queue"> Agg Count <span>180K</span>  </div>
                                    <div class="queue"> Agg Error <span>180K</span>  </div>
                                               
                                </div>

                            </div>
                        </div>
                       
                    </div>
                </div>
                <div class="card p-0">
                    <img src="../../assets/img/graph_agg3.svg" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section> 

