import { Component, OnInit, ViewChild } from '@angular/core';
import { smDataService } from '@app/app.service';
import { appService } from '@app/_services/all';
import { UtilService } from '@app/_services/util.service';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

import {NgSelectModule, NgOption} from '@ng-select/ng-select';

import * as Highcharts from 'highcharts';
import highcharts3D from 'highcharts/highcharts-3d';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsSeriesLabel from 'highcharts/modules/series-label';
import HighchartsAccessibility from 'highcharts/modules/accessibility';


highcharts3D(Highcharts);
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
HighchartsBoost(Highcharts);
HighchartsNoData(Highcharts);
HighchartsSeriesLabel(Highcharts);
HighchartsAccessibility(Highcharts);
declare interface Custom extends Highcharts.PointOptionsObject {
  customProperty: any;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  metricsList:any[];
  closedJobsCount:any;
  inProgressJobsCount:any;
  runningJobs:any;

  filedate:any;
  jobdate: string;

  loading: boolean = true;
  pieChartColors 	  = ['#00c8ff','#595adb','#237dff','#3e5385'];
  lineChartColors 	= ['#00c8ff','#595adb','#237dff','#3e5385'];


  @ViewChild('dt') table: Table;
  clientList: any;
  selectedClient:any = [] ;
  selectedDate:any = [];
  page:number =1;
  size:number = 10;

  isLogged:boolean  = false;
  userEmail:any = '';
  userRole:any = null;
  userAccess:any = null;
  isRoled:boolean = false;
  isLoading:boolean = false;

  dateRange: any = ['Last Quater', 'Last Half', 'Last Year'];
  // selectedLabel: any = 'Default';
  minDate = new Date();
  maxiDate = new Date();
  
  
  constructor(
    private dataService : smDataService,
    private appService  : appService,
    private primengConfig: PrimeNGConfig,
    private utilService: UtilService,
    public pipe:DatePipe,
    public router:Router
  ) { 
    this.minDate = new Date();
    this.maxiDate = new Date();
    this.minDate.setFullYear(2010);
    // this.isLogged   = this.appService?.account?.userValue?.isAuthendicate || false;
    // this.userEmail  = this.appService?.account?.userValue?.email || ''; 
    // if (this.isLogged ) {
    //  // this.getUserLoginDetails();
    // }else{
    //   this.appService.router.navigate(['auth/login']);
    // }
  }

  ngOnInit(): void {
    this.getClientList();
    this.init();
  }

  init(val:any ={}){
    this.getMetricsList(val);
    this.getMetricsInprogressJobCount(val);
    this.getMetricsClosedJobCount(val);
    this.getRunningJobList(val);
    this.getInputByProvider(val);
    this.getQueuingVolumeByProvider(val);
  }

  renderInputByProvidersPieChart(pieSeriesData:any) {
    let self = this;
    Highcharts.chart('pieChart', {
      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },
      pane:{
        center: ['50%', '100%'],
				size: '150%',
      },
      title: {
        text: ''
      },
      tooltip: {
        formatter: function() {
          let wholeNumber = self.utilService.numberWithCommas(this.point.y);
          let per = this.point.percentage.toFixed(2);
          return '<br/><b>' + this.point.name + ':</b> ' +wholeNumber+'<b> ('+per+'%)</b>';
        }
      },
      credits:{
				enabled: false
			},
      plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					depth: 35,
					colors: this.pieChartColors,
					borderWidth: 0,
					// center: ['70%', '90%'],
					size: '100%',
					dataLabels: {
						enabled: true,
						useHTML: true,
						formatter: function () {
							return '<div class="pieLabel"><span class="pieLebleName" style="display: inline-block;width: 40px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" >'+this.point.name+'</span><span class="pieLebleVal">'+this.point.percentage.toFixed(2)+'%</span></div>';
						}
					},
					showInLegend: true
				}
			},
      series: [{
        type: 'pie',
				name: 'Per',
				innerSize:'75%',
        data: pieSeriesData
      }]
    });
  }

  renderQueuingVolumeByProviderslineChart(lineSeriesData:any, categories:any) {
    let self = this;
    Highcharts.chart('lineChart', {
      chart: {
        type: 'line'
      },
      title: {
        text: ''
      },
      subtitle: {
          text: ''
      },
      yAxis: {
          title: {
              text: 'Input Count in K'
          },
          min: 0
      },
      xAxis: {
        //categories: categories,
        type: 'datetime',
        labels: {
          format: '{value:%Y-%b}'
        },
        // dateTimeLabelFormats: { 
        //     month: '%b',
        //     year: '%Y'
        // },
        title: { text: '' }
      },
      
      credits:{
				enabled: false
			},
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
      },
      
      tooltip: {        
        formatter: function () {
          let commonData:any = self.utilService.toFormatedDate(this.x, 'MMMM YYYY');
          return this.points.reduce(function (s, point) {
            //console.log("s point", s, point);
              return '<b>'+s+ '</b><br/><b style=color:'+point.color+' >' + point.series.name + ':</b> ' + self.utilService.num2Abb(point.y);
          }, '<b>' + commonData + '</b>');
        },
        shared: true,
        useHTML: true
      },
      /*
      tooltip: {         
        headerFormat: '<table class="high-tooltip-table" ><tr><th colspan="2">{point.key}</th></tr>',
        pointFormat: '<tr><td><span style="color:{series.color}">{series.name}</span>: <b></td><td>{point.y:,.0f}</b></td></tr>',
        footerFormat: '</table>',        
        valueDecimals: 0,
        split: false,
        shared: true,
        useHTML: true,
      },
      */
      colors: ['#6CF', '#39F', '#06C', '#036', '#000'],
      plotOptions: {
        series: {
            label: {
                connectorAllowed: false,
            },
            
        }
      },
      series: lineSeriesData,
    });
  }

  getMetricsList(params:any ={}): void {
    let data = {
      "type":{
        "value":"getdashboardmetrics",
        "matchMode":"equals"
      }
    }
    let dataList = this.appendData(params, data);
    this.appService.loader.open();
    this.dataService.getMetrics( { filters: JSON.stringify(dataList) } ).subscribe( (res:any) => {
      this.metricsList = res.dashboards;
      this.appService.loader.close();
    },(err:any)=>{
      this.metricsList =[];
      this.appService.loader.close();
    });
  }

  getMetricsInprogressJobCount(params:any = {}): void {
    let data = {
      "type":{
        "value":"getinprogressjobcount",
        "matchMode":"equals"
      },
      "jobstatus":
      {
        "value":"inprogress",
        "matchMode":"equals"
      }
    }
    let dataList = this.appendData(params, data);
    this.appService.loader.open();
    this.dataService.getMetrics( { filters: JSON.stringify(dataList) } ).subscribe( (res:any) => {
      for(var i=0; i<res.dashboards.length; i++){
        this.inProgressJobsCount =  res.dashboards[i]['inprogressjobcount'];
      }
      this.appService.loader.close();
    },(err:any)=>{
      this.inProgressJobsCount =[];
      this.appService.loader.close();
    });
  }

  getMetricsClosedJobCount(params:any = {}): void {
    let data = {
      "type":{
        "value":"getclosedjobcount",
        "matchMode":"equals"
      },
      "jobstatus":
      {
        "value":"completed",
        "matchMode":"equals"
      }
    }
    let dataList = this.appendData(params, data);
    this.appService.loader.open();
    this.dataService.getMetrics( { filters: JSON.stringify(dataList) } ).subscribe( (res:any) => {
      for(var i=0; i<res.dashboards.length; i++){
        this.closedJobsCount =  res.dashboards[i]['completedjobcount'];
      }
      this.appService.loader.close();
    },(err:any)=>{
      this.closedJobsCount =[];
      this.appService.loader.close();
    });
  }

  getRunningJobList(params:any = {}): void {
    let data = {
      "type":
      {
        "value":"getrunningjobs",
        "matchMode":"equals"
      },
      "jobstatus":
      {
        "value":"inprogress",
        "matchMode":"equals"
      }
    }
    let dataList = this.appendData(params, data);
    this.appService.loader.open();
    this.dataService.getRunningJobs( { filters: JSON.stringify(dataList), page: this.page, size:this.size } ).subscribe( (res:any) => {
      this.runningJobs = res.dashboards;
      this.loading = false;
      this.appService.loader.close();
    },(err:any)=>{
      this.runningJobs =[];
      this.appService.loader.close();
    });
  }

  getInputByProvider(params:any = {}): void {
    let data = {
      "type":{
        "value":"getinputbyprovider",
        "matchMode":"equals"
      }
    }
    let dataList = this.appendData(params, data);
    this.appService.loader.open();
    this.dataService.getInputByProviders( { filters: JSON.stringify(dataList) } ).subscribe( (res:any) => {
      var inputByProviderData = res.dashboards;
      var pieData = [];
      for(var i=0; i<inputByProviderData.length; i++){
        var xObj = {};
        xObj['name']  = inputByProviderData[i]['client'];
        xObj['y']     = parseInt(inputByProviderData[i]['totalinputs']);
        pieData.push(xObj);
      }
      this.renderInputByProvidersPieChart(pieData);
      this.appService.loader.close();
    },(err:any)=>{
      this.renderInputByProvidersPieChart([]);
      this.appService.loader.close();
    });
  }

  getQueuingVolumeByProvider(params:any = {}){
    let data = {
      "type":{
        "value":"getqueuingbyprovider",
        "matchMode":"equals"
      }
    }
    let dataList = this.appendData(params, data);
    this.appService.loader.open();
    this.dataService.getQueuingVolumeByProviders( { filters: JSON.stringify(dataList) } ).subscribe( (res:any) => {
      let queuingVolumeData = res.dashboards;
      let xObj = {};
      let categories = [];
      for(let i=0; i<queuingVolumeData.length; i++){ 
        let client  = queuingVolumeData[i]['client'];
        let month   = queuingVolumeData[i]['year']+"-"+this.getMonthFromString(queuingVolumeData[i]['month']);
       // console.log("month", month, categories);
        if(!categories.includes(month)){
          categories.push(month);
        }
        if(!xObj.hasOwnProperty(client)){
          xObj[client] = {};
        }
        xObj[client][month] = queuingVolumeData[i];
      }
      categories.sort(function(a, b){
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
      });
      let result = [];
      let l = -1;
     // console.log("xObj", xObj)
       for(const[client] of Object.entries(xObj)){
         l++;
        let clientData = [];
        //console.log("categories", categories)
        for(let x=0; x<categories.length;x++){
          let month = categories[x];
          let dateSplit = month.split("-");
          let datemonth = this.getMonthFromString(dateSplit[1]);
         // console.log("date month", datemonth);
          // let datetime  = Date.UTC(dateSplit[0],datemonth , 1);
          // let epouch = (new Date(dateSplit[0], dateSplit[1]-1 )).valueOf();
          // let ep = new Date(dateSplit[0], dateSplit[1] )
          // console.log("date", epouch, ep)
          let datetime  = Date.UTC(dateSplit[0],datemonth-1 );
          //console.log("date time", datetime);
          if(xObj[client].hasOwnProperty(month)){            
            clientData.push( [datetime , parseInt(xObj[client][month]['COUNT']) ] );
          }else{
            clientData.push([datetime , 0]);
          }
        } 
        result.push({ type : 'line' , name : client , color: this.lineChartColors[l],  data : clientData  } )      
      }
      //console.log("Result", result);
      this.renderQueuingVolumeByProviderslineChart(result,categories);
      this.appService.loader.close();
    },(err:any)=>{
      this.renderQueuingVolumeByProviderslineChart([], []);
      this.appService.loader.close();
    });
  }

  getClientList(){
    this.appService.loader.open();
    this.dataService.getClient( ).subscribe( (res:any) => {
      this.clientList = res.providers;
      this.appService.loader.close();
    },(err:any)=>{
      this.clientList = [];
      this.appService.loader.close();
    });
  }  
   
  appendData(params:any, data:any){
    let dataList = {};
    if(Object.keys(params).length){
      dataList = Object.assign(data, params);
      // dataList = [...params,data];
      //console.log("dataLoist", dataList);
    }else{
      dataList = data;
    }
    return dataList;
  }

  submitFilter(){
    let p:any = {};
    if(this.selectedDate.length){
      let startDate = this.pipe.transform(this.selectedDate[0],'yyyy-MM-dd');
      let endDate = this.pipe.transform(this.selectedDate[1],'yyyy-MM-dd');
      let selDate = startDate + ',' + endDate;
      p['filedate'] = {"value":selDate,"matchMode":"between"};
    }
    if(typeof(this.selectedClient) == "string"){
        p['client'] = {"value":this.selectedClient,"matchMode":"equals"}
    }
    this.init(p);
  }

  viewJobList(job:any){
    this.router.navigate(['aggregation', job.jobid]);
  }

  clearFilters(){
    this.selectedDate = [];
    this.selectedClient = [];
    this.init();
  }

  clearClient(){
    this.selectedClient = [];
    this.submitFilter();
  }

  getMonthFromString(mon: string):any{
    // return ("JanFebMarAprMayJunJulAugSepOctNovDec".toLowerCase().indexOf(mon.toLowerCase()) / 3 )+ 1;
    var d = Date.parse(mon + " 1, 1970");
    if(!isNaN(d)){
      let x = new Date(d).getMonth() + 1;
      if(x>9){
        return ""+x;
      }else{
        return "0"+x;
      }
    }else{
      return -1;
    }
  }
}
