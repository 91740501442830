export class authConfs {
    isAuthendicated         : boolean = false;
    needAuth                : boolean = true;
    isRedirectLogin         : boolean = true;
    isLogoutSSO             : boolean = true;
    loadSepWindow           : boolean = true;    
    clearSessionStorage     : boolean = true;
    clearLocalStorage       : boolean = true;
    authType                : string = "cognito";
    authStorageLocation     : string = "local";
    authDetailsVariable     : string = "authDetails";
    authTokenVariable       : string = "authToken";
    authCallbackResponse    : string = "authResponse";
    authSuccessRedirectUrl  : string = "/";
    authLogoutRedirectUrl   : string = "/";
    loginPageTitle          : string = "";
    loginPageDesc           : string = "";
}