import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/_helpers/auth.guard';
import { JobRoundTripComponent } from './job-round-trip.component';

const routes: Routes = [
  {
    path: 'aggregation', component: JobRoundTripComponent, canActivate: [AuthGuard],
    children: [
     { path: ':id', component: JobRoundTripComponent }
    ]
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false    })
  ],
  exports: [RouterModule]
})
export class AggregationRoutingModule { }
