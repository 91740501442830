import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/_helpers/auth.guard';
import { DashboardComponent } from './pageModules/dashboard/dashboard.component';
import { HomeComponent } from './coreComponents/home/home.component';

const routes: Routes = [
  { path: '', component:HomeComponent },
  { path: 'dashboard', component:DashboardComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  
 }
