export const environment = {

  production: true,

  appId : "8ij7-smb-dev-202206",

  authConfig : {
    needAuth : true,
    isRedirectLogin :true,
    isLogoutSSO :true,
    loadSepWindow :false,
    clearSessionStorage:true,
    clearLocalStorage:true,
    authType :'cognito',
    authStorageLocation : 'local',
    authDetailsVariable : 'authDetails',
    authTokenVariable : 'token',
    authCallbackResponse : 'authResponse',
    authSuccessRedirectUrl : '/dashboard',
    loginPageTitle : 'SMB - Watch',
    loginPageDesc : 'SMB watch',
    loginPageOptions : {
      wrapperClass : 'full'
    }
  }, 

  cognitoSSO : {
    clientId       : '7jmi38qo2la8nl384oh17n9jk5',
    secretKey      : '15ipnqi5da8044s33elq60sno6ukrbhnvkdt9h5c5md3kifd7i81',
    responseScope  : 'email+openid+phone+profile',
    responseType   : 'code',
    ssoDomainUrl   : 'https://sciera.auth.us-east-1.amazoncognito.com',
    ssoLoginUrl    : '',
    ssoLogoutUrl   : '',
    ssoTokenUrl    : 'https://sciera.auth.us-east-1.amazoncognito.com/oauth2/token',
    redirectUrl    : 'https://smbwatch.sciera.engineering/auth/authendicate',
    siteLoginUrl   : 'https://smbwatch.sciera.engineering/auth/login',
    siteLogoutUrl  : 'https://smbwatch.sciera.engineering/auth/logout'
  },

  msalSSO : {
    clientId    : 'bd725ba9-a79e-4a52-a4fd-d05cec57b9fb',
    tenantId    : '35347e8b-7fa3-4f36-9853-da58952dbc50',
    authority   : 'https://login.microsoftonline.com/common',
    graphApi    : 'https://graph.microsoft.com/v1.0/me',
    redirectUrl : 'https://smbwatch.sciera.engineering/auth/authendicate'
  },

  
  apidetails : {
    smb : {
      appId   : '8ij7-smb-dev-202206',
      apiUrl	: 'https://857s1u1ip1.execute-api.us-east-1.amazonaws.com/produc/smbwatch/api',
      apiKey 	: 'Mkd5VtBcKa8WxJUEidkGW785zgTzGc1z5tLl5cOg'
    }
  },

  dummy:null

};