import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { appService } from '@app/_services/all';

//import { AwsCognitoService } from '../service/aws-cognito.service';
// import { AwsCognitoService } from '@app/authModules/authentication/cognito/service/aws-cognito.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isLogged:boolean  = false;
  isChecked:boolean = false;
  userEmail:any = '';
  constructor(
    private router: Router,
    private appService  : appService,
  ){ 
    this.isLogged   = this.appService?.account?.userValue?.isAuthendicate || false;
    this.userEmail  = this.appService?.account?.userValue?.email || ''; 
    if (this.isLogged ) {
      this.moveToHome();
    }else{
      this.router.navigate(['/']);
    }
    this.isChecked = true;
  }

  ngOnInit(): void {
    
  }

  public moveToHome(): void {
    this.router.navigate(['/dashboard']);
  }

  public login(): void {
    this.router.navigate(['auth/login']);
  }

}
