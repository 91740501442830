import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, finalize, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AwsCognitoService } from '../service/aws-cognito.service';


@Injectable()
export class TokenResolverService implements Resolve<any> {

  auth:any = {}
  conf:any = {}
  token:string = "";
  tokenDetails:any = {};

  constructor(
    private location: Location,
    private awsCognitoService: AwsCognitoService,
    private router: Router
  ) { 
    console.log("===============   TokenResolverService   ==================");
  }

  resolve(): Observable<any | null> {

    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const code: any = urlParams.get('code');

    if (!code) {
      return of(null);
    }

    return this.getTokenDetailsFromCognito(code).pipe(
      finalize(() => {
        this.location.replaceState(window.location.pathname);
      })
    );
  }

  getTokenDetailsFromCognito(code: string): Observable<any | null> {
    return this.awsCognitoService.getTokenDetailsFromCognito(code).pipe(
      switchMap((response: any) => {
        this.token = response.id_token;
        this.tokenDetails = this.awsCognitoService.decodeToken(this.token)
        this.tokenDetails['id_token']     = response.id_token;
        this.tokenDetails['access_token'] = response.access_token;
        localStorage.setItem('authCallbackResponse', JSON.stringify(response) )
        localStorage.setItem('authTokenVariable', this.token )
        localStorage.setItem('authDetailsVariable', JSON.stringify(this.tokenDetails) )
        localStorage.setItem('authType', 'Google' )

        if (response) {
          //this.router.navigate(['/']);
          window.location.href = "/";
        }

        return of(response);
      }),
      catchError ((error) => {
        return error;
      })
    );
  }

}