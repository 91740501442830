import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable ,Subscription } from 'rxjs';


export class GlobalConfs {}

@Injectable({ providedIn: 'root' })
export class GlobalVars { 
  
  private globalSubject: BehaviorSubject<GlobalConfs>;
  private globalObject: Observable<GlobalConfs>;

  constructor() { 
    this.globalSubject = new BehaviorSubject<GlobalConfs>({globalObj:null});
    this.globalObject  = this.globalSubject.asObservable();
    this.globalSubject.next({})
  } 

  public get getGlobal(): GlobalConfs {
    return this.globalSubject.value;
  }

  public setGlobalVar(keyStr:string,value:any):boolean { 
    var clone = Object.assign({}, this.getGlobal);
    //var clone:any = Object.assign(this.getGlobal); // { ...this.getGlobal };
    let obj:any   = clone;
    if(keyStr){
      let keyArray:string[] = keyStr.split("/");
      for(let i=0;i<keyArray.length;i++){
        let key:any = keyArray[i];
        if(parseInt(keyArray[i])==key){
          key = parseInt(keyArray[i]);
        }
        if(!obj[key]){
          obj[key] = {};
          if(i==(keyArray.length-1)){
            obj[key] = value;
          }
          obj = obj[key];
        }else if(typeof obj[key] == "object" || "array"){
          obj = this.getRecursiveHandler(obj,key)
          if( i==(keyArray.length-1) ){
            obj[key] = value;
          }
        }else{
          obj = obj[key];
          return false;
        }       
      }
    }
    this.globalSubject.next(clone)
    return true;
  }

  public getGlobalVar(keyStr:string):any { 
    let obj:any = this.getGlobal
    let value:any = null;
    if(keyStr){
      let keyArray:string[] = keyStr.split("/");
      for(let i=0;i<keyArray.length;i++){
        let key:any = keyArray[i];
        if(parseInt(keyArray[i])==key){
          key = parseInt(keyArray[i]);
        }
        if(!obj[key]){
          return value;
        }else if( i==(keyArray.length-1) ){
          value = obj[key];
        }else if(typeof obj[key] == "object" || "array"){
          obj = this.getRecursiveHandler(obj,key)
        }else{
          obj = obj[key]
        }       
      }
    }
    return value;
  } 

  private getRecursiveHandler(obj:any,key:any):any{
    return obj[key];
  }

}