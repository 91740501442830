                        
<section class="pt-3 pb-3 px-3">
    <div class="container-fluid">
        <div class="row breadcrumb-h">
            <div class="col-12">
                <div class="row breadcrumb-h">
                    <div class="col-6">
                        <div class="d-flex align-items-center justifiy-content-center">
                            <h4 class="heading"><i class="fe fe-briefcase"></i>Dashboard</h4>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="input-group mb-0">
                            <label class="d-block w-100" >Date Range</label>
                            <input type="text" 
                            placeholder="Select Date"
                            class="date-inputcss"
                            name="filedate"
                            id="filedate"
                            [minDate]="minDate"
                            [maxDate]="maxiDate"
                            [(ngModel)]="selectedDate"
                            bsDaterangepicker placement="bottom">
                            <div class="input-group-append">
                               <span class="input-group-text" ><i class="fe fe-calendar"></i></span>
                            </div>
                        </div>

                        <!-- <div class="btn-group date-range-block col-12 p-0" dropdown [insideClick]="true">
                            <button
                              id="button-togl"
                              dropdownToggle
                              type="button"
                              class="btn btn-primary daterange-btn dropdown-toggle"
                              aria-controls="dropdown-togl"
                            >
                              <p class="m-0 d-flex">
                                <span>{{ selectedDate }}</span>
                                <span class="arrow-dwn-icon ml-auto"><i class="ti-angle-down"></i></span>
                              </p>
                            </button>
                            <ul id="dropdown-togl" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-togl">
                              <li *ngFor="let selRange of dateRange" role="menuitem">
                                <a class="dropdown-item" href="javaScript:void(0)" (click)="SelDate(selRange)">{{ selRange }}</a>
                              </li>
                              <li class="divider dropdown-divider"></li>
                              <li role="menuitem" (click)="daterangepicker.toggle()" [attr.aria-expanded]="daterangepicker.isOpen">
                                <a class="dropdown-item" href="javaScript:void(0)" [ngClass]="{ active: false }"> Custom Range</a>
                              </li>
                            </ul>
                            <div class="ts-custom-range-input">
                              <input
                                type="text"
                                placement="left"
                                [minDate]="minDate"
                                [maxDate]="maxiDate"
                                class="date-inputcss"
                                name="filedate"
                                #daterangepicker="bsDaterangepicker"
                                [(ngModel)]="selectedDate"
                                bsDaterangepicker
                              />
                              <div class="input-group-append">
                                <span class="input-group-text" ><i class="fe fe-calendar"></i></span>
                             </div>
                            </div>
                          </div> -->
                         

                        <div class="client-input">
                            <label >Client</label>
                            <ng-select  placeholder="Select Client"  [closeOnSelect]="true"  [items]="clientList"
                            bindLabel="provider" bindValue="provider" name="clientname" [(ngModel)]="selectedClient" (clear)="clearClient()"
                            > </ng-select>
                        </div>
                        
                        <div class="filter-confirm">
                            <button type="button"  [disabled]="!(selectedClient.length || selectedDate.length)" class="btn btn-primary btn-sm"  (click) ="submitFilter()">Search</button>
                            <button type="button"  [disabled]="!(selectedClient.length || selectedDate.length)" class="btn btn-default border btn-sm ml-4"  (click) ="clearFilters()">Clear</button>
                        </div>
                        <!-- <div>
                            <button type="button" id='go_LoggerBtn' [disabled]="!(selectedClient || selectedDate)" class="btn-info btn-sm go-btn" style="margin-top: 25px;" (click)="submitFilter()">GO</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>            
</section>
        
<section class=" px-3">
    <div class="container-fluid">
        <div class="row card-statistics no-gutters" *ngFor="let mt of metricsList">
            <div class="col-4">
                <div class="card h-100">
                    <div class="card-bx">
                        <div class="statistics">
                            <h6>Total Inputs</h6>
                            <h5 class="mb-0">{{ mt.totalinput | thousandSuff }}</h5>
                        </div>
                        <div class="statistics">
                            <h6>Client</h6>
                            <h5 class="mb-0">{{ mt.totalclients }}</h5>
                        </div>
                        <div class="statistics">
                            <h6>Jobs</h6>
                            <h5 class="mb-0">{{ mt.jobscount }}</h5>
                        </div>
                        <div class="statistics">
                            <h6>Exported </h6>
                            <h5 class="mb-0">{{ mt.exported | thousandSuff }}<span class="value-text ml-2 value-percentage">{{ mt.exported_percent }}%</span></h5>
                        </div>
                        <div class="statistics">
                            <h6>NR</h6>
                            <h5 class="mb-0">{{ mt.NR | thousandSuff }}<span class="value-text ml-2 value-percentage">{{ mt.NR_percent }}%</span></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="card">
                    <h3>Aggregation</h3>
                    <div class="card-bx">
                        <div class="statistics statistics-ch">
                            <h4>Processed</h4>
                            <h5 class="mb-0">{{ mt.aggprocessed | thousandSuff }}<span class="value-text ml-2 value-percentage">{{ mt.aggprocessed_percent }}%</span></h5>
                        </div>
                        <div class="statistics">
                            <h4>Error</h4>
                            <h5 class="mb-0">{{ mt.aggError | thousandSuff }}<span class="value-text ml-2 value-percentage">{{ mt.aggError_percent }}%</span></h5>
                        </div>
                    </div>
              
                </div>
            </div>
            <div class="col-2">
                <div class="card">
                    <h3>Parser</h3>
                    <div class="card-bx">
                        <div class="statistics statistics-ch">
                            <h4>Cleansed</h4>
                            <h5 class="mb-0">{{ mt.Cleansed | thousandSuff }}<span class="value-text ml-2 value-percentage">{{ mt.Cleansed_percent }}%</span></h5>
                        </div>
                        <div class="statistics">
                            <h4>Error</h4>
                            <h5 class="mb-0">{{ mt.Cleansed_error | thousandSuff }}<span class="value-text ml-2 value-percentage">{{ mt.Cleansed_error_percent }}%</span></h5>
                        </div>
                    </div>
              
                </div>
            </div>
            <div class="col-4 pr-0">
                <div class="card h-100">
                    <div class="card-bx">
                        <div class="statistics">
                            <h6>No of I/P files </h6>
                            <h5 class="mb-0">{{ mt.noofinputfiles }}</h5>
                        </div>
                        <div class="statistics">
                            <h6>Agg Avg</h6>
                            <h5 class="mb-0">{{ mt.aggavg  | thousandSuff }}</h5>
                        </div>
                        <div class="statistics">
                            <h6>Inprogress Jobs</h6>
                            <h5 class="mb-0">{{ inProgressJobsCount }}</h5>
                        </div>
                        <div class="statistics">
                            <h6>Closed Jobs </h6>
                            <h5 class="mb-0">{{ closedJobsCount }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>    

<section class="px-3">
    <div class="container-fluid">
        <div class="row mt-3 mb-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <h3 class="title"><span>C</span>urrently Running Jobs</h3>
                    </div>
                </div>
                <div class="card p-0">        
                    <p-table #dt 
                        [value]="runningJobs" 
                        styleClass="p-datatable-runningJobs" 
                        [rowHover]="true" 
                        [rows]="10" 
                        [showCurrentPageReport]="true"  
                        [loading]="loading" 
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
                        [filterDelay]="0" 
                        [globalFilterFields]="['jobname','client']" 
                        [scrollable]="true" 
                        scrollHeight="315px">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="jobname">Job Name<p-sortIcon field="jobname"></p-sortIcon></th>
                                <th pSortableColumn="client">Client <p-sortIcon field="client"></p-sortIcon></th>
                                <th pSortableColumn="totalinputs">Total Input <p-sortIcon field="totalinputs"></p-sortIcon></th>
                                <th pSortableColumn="filedate">File Date <p-sortIcon field="filedate"></p-sortIcon></th>
                                <th pSortableColumn="validrecord">Valid Record <p-sortIcon field="validrecord"></p-sortIcon></th>
                                <th pSortableColumn="jobqueuedate">Job Queue Date <p-sortIcon field="jobqueuedate"></p-sortIcon></th>
                                <th pSortableColumn="estdate">Est Date <p-sortIcon field="estdate"></p-sortIcon></th>
                                <th pSortableColumn="progress">Progress <p-sortIcon field="progress"></p-sortIcon></th>
                                <th pSortableColumn="action">Action <p-sortIcon field="action"></p-sortIcon></th>
                            </tr>
                            <!-- <tr>
                                <th>
                                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'jobname', 'startsWith')" placeholder="Search by Job Name" class="p-column-filter">
                                </th>
                                <th>
                                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'client', 'contains')" placeholder="Search by Client" class="p-column-filter">
                                </th>
                                <th></th>
                            </tr> -->
                        </ng-template>
                        <ng-template pTemplate="body" let-runningJobs>
                            <tr class="p-selectable-row">
                                <td>
                                    <span class="p-column-title">Job Name</span>
                                    <span class="image-text"></span>{{ runningJobs.jobname }}
                                </td>
                                <td>
                                    <span class="p-column-title">Client</span>
                                    <span class="image-text"></span>{{ runningJobs.client }}
                                </td>
                                <td>
                                    <span class="p-column-title">Total Input</span>
                                    <span class="image-text"></span>{{ runningJobs.totalinputs | thousandSuff }}
                                </td>
                                <td>
                                    <span class="p-column-title">File Date</span>
                                    <span class="image-text"></span>{{ runningJobs.filedate | date:'dd MMM YY' }}
                                </td>
                                <td>
                                    <span class="p-column-title">Valid Record</span>
                                    <span class="image-text"></span>{{ runningJobs.validrecord | thousandSuff }}
                                </td>
                                <td>
                                    <span class="p-column-title">Job Queue Date</span>
                                    <span class="image-text"></span>{{ runningJobs.jobqueuedate | date:'dd MMM YY' }}
                                </td>
                                <td>
                                    <span class="p-column-title">Est Date</span>
                                    <span class="image-text"></span>{{ runningJobs.estdate | date:'dd MMM YY' }}
                                </td>
                                <td>
                                    <span class="p-column-title">Progress</span>
                                    <!--<p-progressBar [title]="runningJobs.progress" [value]="runningJobs.progress" [showValue]="true">%</p-progressBar>-->
                                    <progressbar [value]="runningJobs.progress" type="warning">{{ runningJobs.progress }}%</progressbar>
                                </td>
                                <td style="text-align: center">
                                    <!-- <a  routerLink="jobs" class="btn btn-outline-default" (click)="viewJobList($event)"><i class="fe fe-eye"></i></a> -->
                                    <a class="btn btn-outline-default" (click)="viewJobList(runningJobs)"><i class="fe fe-eye"></i></a>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">No customers found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</section> 

<section class="px-3">
    <div class="container-fluid">
        <div class="row mt-3 mb-3">
            <div class="col-6">
                <div class="row mt-3">
                    <div class="col-12">
                        <h3 class="title"><span>I</span>nputs By Client</h3>
                    </div>
                </div>
                <div class="card p-2">
                    <div id="pieChart" style="width:100%; height:400px;"></div>
                </div>
            </div>
            <div class="col-6">
                <div class="row mt-3">
                    <div class="col-12">
                        <h3 class="title"><span>Q</span>ueuing volume by Client</h3>
                    </div>
                </div>
                <div class="card p-2">
                    <div id="lineChart" style="width:100%; height:400px;"></div>
                </div>
            </div>
        </div>
    </div>
</section> 
