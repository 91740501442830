export class User {
    id: string = "";
    name: string = "";
    email: string = "";
    picture: string = "";
    expTime: number = 0;
    loginTime: number = 0;
    authType: string = "";
    provider: string = "";
    idToken: string = "";
    accessToken: string = "";
    isAuthendicate: boolean = false;
    roleDetails: any = {};
    accessDetails: any = {};
    otherDetails: any = {};
}