export const environment = {

  production: true,

  appId : "8ij7-smb-dev-202206",

  authConfig : {
    needAuth : true,
    isRedirectLogin :true,
    isLogoutSSO :true,
    loadSepWindow :false,
    clearSessionStorage:true,
    clearLocalStorage:true,
    authType :'cognito',
    authStorageLocation : 'local',
    authDetailsVariable : 'authDetails',
    authTokenVariable : 'token',
    authCallbackResponse : 'authResponse',
    authSuccessRedirectUrl : '/dashboard',
    loginPageTitle : 'SMB - Watch',
    loginPageDesc : 'SMB watch',
    loginPageOptions : {
      wrapperClass : 'full'
    }
  }, 


  cognitoSSO : {
    clientId       : '5stlceufgo9oifqngq9jmmoiem',
    secretKey      : 'hjju0j5823h1t3fg04eq65m3eeuljh2vnbalqp9j6j9a5ei0lqe',
    responseScope  : 'email+openid+phone+profile',
    responseType   : 'code',
    ssoDomainUrl   :  'https://sciera.auth.us-east-1.amazoncognito.com',
    ssoLoginUrl    :  '',
    ssoLogoutUrl   :  '',
    ssoTokenUrl    :  'https://sciera.auth.us-east-1.amazoncognito.com/oauth2/token',
    redirectUrl    :  'https://smbwatch-dev.sciera.engineering/auth/authendicate',
    siteLoginUrl   :  'https://smbwatch-dev.sciera.engineering/auth/login',
    siteLogoutUrl  :  'https://smbwatch-dev.sciera.engineering/auth/logout'
  },
    
  msalSSO : {
    clientId    : 'bd725ba9-a79e-4a52-a4fd-d05cec57b9fb',
    tenantId    : '35347e8b-7fa3-4f36-9853-da58952dbc50',
    authority   : 'https://login.microsoftonline.com/common',
    graphApi    : 'https://graph.microsoft.com/v1.0/me',
    redirectUrl : 'https://smbwatch-dev.sciera.engineering/auth/authendicate'
  },
  
  apidetails : {
    smb : {
      appId   : '8ij7-smb-dev-202206',
      apiUrl	: 'https://ub2hvccx4e.execute-api.us-east-1.amazonaws.com/dev/smbwatch/api',
      apiKey 	: 'OM4XXTilbBaoxzd9UfNNNLPqjfbHrNH5Pd1Y9dGe'
    }
  },

  dummy:null

};