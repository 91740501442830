export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}

export class Alert {
    id: string;
    type: AlertType;
    message: string;
    autoClose: boolean;
    keepAfterRouteChange: boolean;
    fade: boolean;
    constructor(init?:Partial<Alert>) {
        this.id = "";
        this.type = AlertType.Info,
        this.message = "";
        this.autoClose = true;
        this.keepAfterRouteChange = true;
        this.fade = true;
        Object.assign(this, init);
    }
}
