import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';

import { HttpHelper } from './_helpers/http-helper';
import { appService } from './_services/all';
import { apiDetails } from './_interfaces/apiDetails';

@Injectable({  providedIn: 'root' })
export class smDataService {

    public apiDetails: apiDetails;    
    public apiHttpHeaders: any = {};

    public userEmail    = '';
    public apiBaseUrl   = '';
    public apiBaseKey   = '';

        
    constructor(
        private httpHelper: HttpHelper,
        private appService: appService
    ){
        this.apiDetails   = this.appService.env?.apidetails['smb'];
        this.userEmail    = this.appService.account?.userValue?.email || "admin@sciera.com";      
        this.setHeader();        
    }
  
    public init(): void {

    }

    private setHeader(): void {
        this.apiHttpHeaders  = {
            'Content-Type'  : 'application/json',      
            'x-api-key'     : this.apiDetails.apiKey
        }
    }

    // public getJobs(data: any = {}){ 
    //     const url      = this.apiDetails.apiUrl + '/jobs/jobdetails';
    //     const headers  = this.apiHttpHeaders;
    //     return this.httpHelper.apiResponse('GET', url, data, headers, true);
    // }

    public getMetrics(data: any = {}){ 
        const url      = this.apiDetails.apiUrl + '/dashboards/metrics';
        const headers  = this.apiHttpHeaders;
        return this.httpHelper.apiResponse('GET', url, data, headers, true);
    }
    public getRunningJobs(data: any = {}){ 
        const url      = this.apiDetails.apiUrl + '/dashboards/jobrunning';
        const headers  = this.apiHttpHeaders;
        return this.httpHelper.apiResponse('GET', url, data, headers, true);
    }
    public getInputByProviders(data: any = {}){
        const url      = this.apiDetails.apiUrl + '/dashboards/metrics';
        const headers  = this.apiHttpHeaders;
        return this.httpHelper.apiResponse('GET', url, data, headers, true);
    }
    public getQueuingVolumeByProviders(data: any = {}){
        const url      = this.apiDetails.apiUrl + '/dashboards/metrics';
        const headers  = this.apiHttpHeaders;
        return this.httpHelper.apiResponse('GET', url, data, headers, true);
    }

    /* Jobs Service */

    public getJobs(data: any = {}){ 
        const url      = this.apiDetails.apiUrl + '/jobs/jobdetails';
        const headers  = this.apiHttpHeaders;
        return this.httpHelper.apiResponse('GET', url, data, headers, true);
    }

    public getClient(data: any = {}){ 
        const url      = this.apiDetails.apiUrl + '/providers';
        const headers  = this.apiHttpHeaders;
        return this.httpHelper.apiResponse('GET', url, data, headers, true);
    }

    public getJobslist(data: any = {}){ 
        const url      = this.apiDetails.apiUrl + '/jobs/jobdetails';
        const headers  = this.apiHttpHeaders;
        return this.httpHelper.apiResponse('GET', url, data, headers, true);
    }

     public getSearchType(data: any = {}){ 
        const url      = this.apiDetails.apiUrl + '/searchTypes';
        const headers  = this.apiHttpHeaders;
        return this.httpHelper.apiResponse('GET', url, data, headers, true);
    }

    public getSearchEngine(data: any = {}){
        const url      = this.apiDetails.apiUrl + '/searchEngines';
        const headers  = this.apiHttpHeaders;
        return this.httpHelper.apiResponse('GET', url, data, headers, true);
    }

    public getJobchart(data: any = {}){
        const url      = this.apiDetails.apiUrl + '/jobs/jobdetails';
        const headers  = this.apiHttpHeaders;
        return this.httpHelper.apiResponse('GET', url, data, headers, true);
    }

}