import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UtilService {

    constructor(private datePipe: DatePipe){
    }

    public toCamalcase(str: string): string{
        str = this.toCaptalize(str);
        str = this.replace(str, ' ', '');
        return str;
    }

    public toCaptalize(str: string): string{
        str = this.replaceExpAlphaNum(str)
        str = str.toLowerCase().replace(/\b[a-z]/g, (letter) => {
            return letter.toUpperCase();
        });
        str = str.trim();
        return str;
    }

    public replaceAll(str: string, searches: string[], replacements: string){
        if (!str) {
            return '';
        } else {
            for(const search of searches){
                str = str.replace(new RegExp(search, 'g'), replacements);
            }
        }
        return str;
    }

    public replace(str: string, search: string, replacement: string): string{
        if (!str) {
            return '';
        } else {
            return str.replace(new RegExp(search, 'g'), replacement);
        }
        return str;
    }

    public replaceExpAlphaNum(str: string, replacement: string= ' '): string{
        if (str) {
            str = str.replace(/[^A-Za-z0-9]|\s+|\r?\n|\r/gmi, replacement);
            if (str) {
                str = str.trim();
            }
            return str;
        } else {
            return str;
        }
    }

    public convertToCamalCase(str: string): string {
        return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    }

    public convertToKebebCase(str: string): string {
        return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('-');
    }

    public convertToSnakeCase(str: string): string {
        str = this.convertToCamalCase(str);
        return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}` );
    }

    public convertToPascalCase(str: string): string {
        return str.replace(/\w+/g, (w) => {
            return w[0].toUpperCase() + w.slice(1).toLowerCase();
        });
    }

    public formatDate(date: Date): string {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        return [year, month, day].join('-');
    }

    public formatDate2(dateStr: string ): string {
        return new Date(dateStr).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' });
    }

    public toFormatedDate(dateStr: any, formatStr: string): string {
        if (!dateStr) {
            return "";
        }
        var date = new Date(dateStr);
        return this.datePipe.transform(date, formatStr);
    }

    public getDifferenceInDays(date1: any, date2: any): any {
        const diffInMs: any = Math.abs(date2 - date1);
        return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    }

    public getDifferenceInHours(date1: any, date2: any): any {
        const diffInMs = Math.abs(date2 - date1);
        return Math.ceil(diffInMs / (1000 * 60 * 60));
    }

    public getDifferenceInMinutes(date1: any, date2: any): any {
        const diffInMs = Math.abs(date2 - date1);
        return Math.ceil(diffInMs / (1000 * 60));
    }

    public getDifferenceInSeconds(date1: any, date2: any): any{
        const diffInMs = Math.abs(date2 - date1);
        return Math.ceil(diffInMs / 1000);
    }

    public num2Abb(val: any): string {  
        var s = ["", "K", "M", "B", "T"];
        var sNum = Math.floor(("" + val).length / 3);
        var sVal = parseFloat((sNum != 0 ? (val / Math.pow(1000, sNum)) : val).toPrecision(2));
        var rVal = "";
        if (sVal % 1 != 0) {
            rVal = sVal.toFixed(2);
        } else {
            rVal = sVal + "";
        }
        if (val < 1000) {
            return val;
        } else {
            return rVal + s[sNum];
        }        
    }

    public numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    public getDiffPer(n1: any, n2: any): any{
        if (n1 && n2) {
            return ((n1 - n2) / n1) * 100;
        } else if(n1){
            return 0;
        } else {
            return 0;
        }        
    }

    public minimizeLabel(str: string, level: number = 3): string{
        let result: string = '';
        str = this.replaceAll(str, ['-', '_'], ' ');
        let splits = str.split(" ");
        if (splits.length > 1) {
            for (var i = 0; i < splits.length; i++){
                let subStr: string = splits[i];
                subStr = this.toCaptalize(subStr);
                subStr = subStr.substring(0, level);
                result += subStr;
            }
        } else {
            result = str;
        }        
        return result;
    }

}

/*
export function convertListToMap<T, Key extends keyof T, Val extends keyof T> (
  list: T[],
  keyForMap: Key,
  valueForMap?: Val,
): Map<T[Key], (T | T[Val])[]> {
  const map = new Map<T[Key], (T | T[Val])[]>();
  list.forEach((item) => {
    const mapKey = item[keyForMap];
    if (!map.has(mapKey)) {
      map.set(mapKey, []);
    }
    const valuesList = map.get(mapKey);
    const value = valueForMap ? item[valueForMap] : item;
    const newValuesList = _.union(valuesList, [ value ]);
    map.set(mapKey, newValuesList);
  });

  return map;
}

export function mapByKey<T, Key extends keyof T, Val extends keyof T> (
  list: T[],
  key: Key,
  valueKey?: Val,
): Map<T[Key], T | T[Val]> {
  const map = new Map<T[Key], T | T[Val]>();
  list.forEach((item) => {
    const mapKey = item[key];
    if (!map.has(mapKey)) {
      const mapValue = valueKey ? item[valueKey] : item;
      map.set(mapKey, mapValue);
    }
  });

  return map;
}
*/