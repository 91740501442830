import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParamsOptions, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError , map} from 'rxjs/operators';

import { appService } from '@app/_services/all';
import { UtilService } from '@app/_services/util.service';

@Injectable({ providedIn: 'root' }) 
export class HttpHelper  {
	
    account:any;
    apiKey:string;
    httpHeaders:any;
    retryOptions:number = 0;

    constructor( 
        private router: Router,
        private http: HttpClient, 
        private util: UtilService,
        private appService: appService 
    ) {
        this.account = this.appService.account;
        this.apiKey  = this.appService.env?.apidetails?.profiler?.apiKey;
        this.httpHeaders = {
            "Cache-Control" : "no-cache, no-store",
            "Content-Type"  : "application/json",
            "x-api-key"	    : this.apiKey
        }
    }

    public apiResponse(method:string,url:string,parms?:any,headerOptions?:any,toEncode:boolean = false):any{
        if(!headerOptions){
            headerOptions = this.httpHeaders;
        }
        if(method.toLowerCase()=="get"){
            return this.getRequest(url,parms,headerOptions)
        }else if(method.toLowerCase()=="post"){
            return this.postRequest(url,parms,headerOptions)
        }else if(method.toLowerCase()=="put"){
            return this.putRequest(url,parms,headerOptions)
        }else if(method.toLowerCase()=="put"){
            return this.deleteRequest(url,headerOptions)
        }else{
            throw "Unknow request option : "+method;
            return null;
        }
    }

    private getRequest(url:string, parms:any, headerOptions:any){
        const httpParams: HttpParamsOptions = parms as HttpParamsOptions;
        let httpOptions:any = { headers: new HttpHeaders(headerOptions) , params: new HttpParams( httpParams ) };     

        return this.http.get<any>(url, { params : parms } ).pipe(
            map(response => {
                return response;
            }),
            catchError(this.handleError),
            retry(this.retryOptions)
        );       
    }  

    private postRequest(url:string,body:any,headerOptions:any):any{         
        let httpOptions:any = { headers: new HttpHeaders(headerOptions) }
        let data:any = {};
        if(body)data = body;
        return this.http.post<any>(url, JSON.stringify(data), httpOptions ).pipe(
            map(response => {
                return response;
            }),
            catchError(this.handleError),
            retry(this.retryOptions)
        )
    } 

    private putRequest(url:string,body:any,headerOptions:any):any{  
        let httpOptions:any = { headers: new HttpHeaders(headerOptions) }
        let data:any = {};
        if(body)data = body;      
        return this.http.put<any>(url, JSON.stringify(data), httpOptions ).pipe(
            map(response => {
                return response;
            }),
            catchError(this.handleError),
            retry(this.retryOptions)
        )

    } 
    
    private deleteRequest(url:string,headerOptions:any){
        let httpOptions:any = { headers: new HttpHeaders(headerOptions) }
        return this.http.get<any>(url, httpOptions ).pipe(
          catchError(this.handleError),
          retry(this.retryOptions)
        );
    }

    private handleError(error:HttpErrorResponse) {
        console.log("=====================================")
        console.log(error)
        let errorMessage:string = '';
        //return new ErrorObservable(`Error retreiving travellers data. ${error.statusText || 'Unknown'} `);

        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        //window.alert(errorMessage);
        return throwError(errorMessage);
    }
	
    private formGetParms(data: any = {}, toEncode:boolean = false){
        let response = "";
        for (const [objKey, objVal] of Object.entries(data)) {
            if(response !=""){
                response += "&";
            }
            if(toEncode){
                let val = this.util.replaceAll(JSON.stringify(objVal),['\"'],'"');
                response += objKey+"="+encodeURI(JSON.stringify(objVal).replace(new RegExp('\"', 'g'), '"'));
            }else{
                response += objKey+"="+JSON.stringify(objVal);
            }
            
        }
        return response;
    }

}