import { NgModule , CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressbarModule,ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { TableModule } from 'primeng/table';
import {ProgressBarModule} from 'primeng/progressbar';

import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';


import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { ThousandSuffixesPipe } from '@app/_helpers/numberconv_pipes';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  declarations: [
    DashboardComponent,
    ThousandSuffixesPipe
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ProgressbarModule,
    TableModule,
    ProgressBarModule,

    FormsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
    ProgressbarConfig
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class DashboardModule { }
