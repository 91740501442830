import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/_helpers/auth.guard';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {
    path: 'dashboard', component: DashboardComponent, canActivate:[AuthGuard],
    children: []
  }
]


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false    })
  ],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
