<div class="smb-login" *ngIf="isChecked && !isLogged">
    <div class="smb-inner">
       <div class="container">
          <div class="row align-items-center justify-content-center">
              <div class="col-10">
                  <div class="bg-white smb-imgb">
                    <div class="row">
                        <div class="col-md-6 pr-0">
                            <div class="login-bg">
                                <div class="d-flex h-100 align-items-center justify-content-center p-4 flex-column text-white">
                                    <h4 class="text-white">Welcome to SMB WATCH</h4>
                                    <br>
                                    <p class="text-center mb-0">SmbWatch application uses to track the business existence check and stats by client,job,job status and overall process of the Small medium businesses and it tracks Changes to neighbourhood small and medium businesses. updated every insight regarding new or existing business, and industry type.</p>
                                    <p></p>
                                </div>
                            </div>
                          </div>
                          <div class="col-md-6 pl-0">
                              <div class="form-contents ">
                                <div class="row justify-content-center p-5 my-5">
                                    <img src="../../assets/img/logo.svg" alt="Image" class="img-fluid logo-pos d-block mb-4" height="25" >
                                    <div class="col-md-12">
                                          <h6 class="mb-4 text-center">Sign in to your account</h6>
                                          <div class=" text-center">
                                             <a (click)="login()" class="btn border p-2 rounded w-100 d-block">
                                               <img src="../../assets/img/google.png" width="20" class="mr-2">Sign in With Google
                                             </a>
                                          </div>
                                    </div>
                                 </div>
                              </div>                           
                          </div>    
                      </div>
                  </div> 
              </div>
          </div>
       </div>
    </div>
</div>