import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { appService } from '@app/_services/all';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLogged:boolean  = false;
  userName: string;
  userPic: any;
  constructor(public router : Router, private appService  : appService, ) {
    this.isLogged   = this.appService?.account?.userValue?.isAuthendicate || false;
    this.userName = this.appService?.account?.userValue?.name || '';
    this.userPic = this.appService?.account?.userValue?.picture || null;
   }

  ngOnInit(): void {
  }

}
