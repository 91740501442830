import { NgModule , CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressbarModule,ProgressbarConfig } from 'ngx-bootstrap/progressbar';

import { AggregationRoutingModule } from './job-round-trip-routing.module';
import { JobRoundTripComponent } from './job-round-trip.component';

@NgModule({
  declarations: [
    JobRoundTripComponent
  ],
  imports: [
    CommonModule,
    AggregationRoutingModule,
    ProgressbarModule
  ],
  providers: [
    ProgressbarConfig
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AggregationModule { }
