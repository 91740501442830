<div class="app-theme-wrapper" >
    <div class="{{ isDynamicComponent ? 'card' : 'card card-form' }}">
        <div *ngIf="!isDynamicComponent" class="card-header">{{fTitle}}</div>
        <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" >
                <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>  
                <button type="submit" class="btn btn-info pull-right submit-button" (click)="onOriginal()">Original theme</button>
                <button type="submit" class="btn btn-outline-primary pull-right submit-button" (click)="onSubmit()">Save on browser</button>            
            </form>
        </div>
        <p class="action-message">{{requestMessage}}</p>
    </div>
</div>