<div class="app-theme-settings" >
	<span class="btn btn-sm btn-default" title='Edit theme settings' (click)="upDateTheme()">
		<em class='fa fa-cog'></em>
  </span>
</div>

<ng-template #modalContent let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Theme-settings</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" id="dynamicDiv" ></div>
</ng-template>