import { Component, OnInit } from '@angular/core';
import {  Router,  NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.scss']
})
export class BreadCrumComponent implements OnInit {

  routeSubscription: Subscription;
  breadcrumOpts:any[] = []

  constructor(private router: Router) {
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let url:string = event.url.toLowerCase() || "";
        this.breadcrumOpts = [];
        let fullUrl:string = "";
        let urlSplits:any[] = url.split('/')
        for(let i:number = 0;i<urlSplits.length;i++){
          let urlsplit = urlSplits[i];
          let root = false;
          let mid = true;
          let last = false;
          if(fullUrl!='/'){
            fullUrl += "/";
          }
          fullUrl += urlsplit;
          if(urlsplit==''){
            root      = true;
            urlsplit  = 'Home';
          }
          if(i == (urlSplits.length-1)){
            last = true;
          }
          if(root || last){
            mid = false;
          }
          this.breadcrumOpts.push({label:urlsplit,link:fullUrl,isRoot:root,isLast:last, isMid:mid})
        }
      }
    })
  }

  ngOnInit(): void {
  }

}
