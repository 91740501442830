import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { from, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax'; 

import { SyncRequestService } from 'ts-sync-request/dist';

export class AppSettings {}

@Injectable({ providedIn: 'root' })
export class SettingsService  {
  private readonly configUrlPath: string = '/assets/config/app-settings.json';
  private isLoaded:boolean = false;  
  private appConfSubject: BehaviorSubject<AppSettings>;
  public localStorageName:string = 'ada-theme-settings';

  constructor( @Inject(DOCUMENT) private document: Document) {
    this.appConfSubject        = new BehaviorSubject<AppSettings>({});
    if(!this.isLoaded){
      localStorage.removeItem(this.localStorageName);
      this.loadAppSettings_ajaxSync();
      this.loadAppSettings_tsSync();
    }  

  }

  get settings(): any {
    return this.appConfSubject.value;
  }

  get storageVariable():string {
    return this.localStorageName;
  }

  private loadAppSettings_fetch() {
    const data = from(fetch(this.configUrlPath));
    data.subscribe({
      next(res) { 
        console.log(res.body); 
      },
      error(err) { console.error('Error: ' + err); },
      complete() { console.log('Completed'); }
    });
  }

  private loadAppSettings_ajaxSync() {
    const settings = ajax({  
      url: this.configUrlPath, 
      async:false, 
      method: 'GET',  
      headers: { },  
      body: { }  
    });
    settings.subscribe(  
      res => {
        this.appConfSubject.next(res.response)
      },  
      err => console.error(err)  
    ); 
  }  

  private loadAppSettings_tsSync() {
    let response = new SyncRequestService().get<AppSettings>(this.configUrlPath);
    this.appConfSubject.next(response)
  }

  /*
  private loadAppSettings_3() {
    var readFileAsObservable = Rx.Observable.bindNodeCallback(fs.readFile);
    var result = readFileAsObservable('./roadNames.txt', 'utf8');
    result.subscribe(x => console.log(x), e => console.error(e));
  }

  private loadAppSettings_4() {
    const boundSyncFn = Rx.Observable.bindCallback(this.iCallMyCallbackSynchronously);
    const boundAsyncFn = Rx.Observable.bindCallback(this.iCallMyCallbackSynchronously, null, Rx.Scheduler.async);    
    boundSyncFn().subscribe(() => console.log('I was sync!'));
    boundAsyncFn().subscribe(() => console.log('I was async!'));
    console.log('This happened...');
  }

  iCallMyCallbackSynchronously(){

  }
  */


}