import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AccountService } from '@app/_services';
import { apiDetails } from '@app/_interfaces/apiDetails';
import { appService } from '@app/_services/all';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    public apiDetails: apiDetails;

    constructor(
        private accountService: AccountService,
        private appService: appService
    ) {
        this.apiDetails   = this.appService.env?.apidetails['smb'];
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        request = request.clone({ 
            setHeaders: { 
                'x-api-key': this.apiDetails.apiKey 
            }
        })

        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                //this.accountService.logout();
            }            
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
    
}