import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthModulesComponent } from './auth-modules.component';

import { TokenResolverService } from './resolver/token-resolver.service';

const routes: Routes = [
  {
    path: 'auth/login',
    component: AuthModulesComponent
  },
  {
    path: 'auth/authendicate',
    component: AuthModulesComponent,
    resolve: {
      access: TokenResolverService
    }
  },
  {
    path: 'auth/logout',
    component: AuthModulesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthModulesRoutingModule { }
