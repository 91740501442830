export class BsModal {
    id: string;
    classes:string[];
    title:string;
    content: string;
    close: boolean;
    dismiss: boolean;
    options:any;
    constructor(init?:Partial<BsModal>) {
        Object.assign(this, init);
    }
}
