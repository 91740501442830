import { Injectable } from '@angular/core';

import * as localEnv from '@app/environment';
import * as devEnv from '@app/environment.dev';
import * as sandboxEnv from '@app/environment.sandbox';
import * as prodEnv from '@app/environment.prod';
import { throwError , Observable } from 'rxjs';
import { reject } from 'lodash';

interface Configuration {
  production: boolean,
  appId : string,
  authConfig : any,
  apidetails : any,
  dummy : any
}

@Injectable({ providedIn: 'root' })
export class EnvService {
    private envLoc:string = "null";
    private allEnvs:any = {};
    private envVar:any = {};  
    private configuration: Observable<Configuration>;

    constructor() {
      this.allEnvs = {
        Prod    : prodEnv.environment,
        Sandbox : sandboxEnv.environment,
        Dev     : devEnv.environment,
        Local   : localEnv.environment,
      }
      this.envVar         = this.allEnvs.Prod;
      this.configuration  = this.allEnvs.Prod;
    }

    init(): Promise<void> {
      return new Promise( resolve => {
          this.setEnvVariables();
          resolve();
        }
      );
    }

    get evnLocation(): string {
      return this.envLoc;
    }

    get env(): any {
      return this.envVar;
    }

    private setEnvVariables(): void {
      const hostname = window && window.location && window.location.hostname;  
      if (/^.*localhost.*/.test(hostname)){
        this.envVar         = this.allEnvs.Local;
        this.configuration  = this.allEnvs.Local;
        this.envLoc         = "loc";
        console.log("---env matched local----")
      } else if (/^smbwatch.sciera.engineering/.test(hostname)){ 
        this.envVar         = this.allEnvs.Prod;
        this.configuration  = this.allEnvs.Prod;
        this.envLoc         = "prod";
        console.log("---env matched -prod---")
      } else if (/^smbwatch-dev.sciera.engineering/.test(hostname)){ 
        this.envVar         = this.allEnvs.Dev;
        this.configuration  = this.allEnvs.Dev;
        this.envLoc         = "dev";
        console.log("---env matched -prod---")
      } else {
        this.envVar         = this.allEnvs.Local;
        this.configuration  = this.allEnvs.Local;
        this.envLoc         = "loc";
        console.log("---env matched else----")
        console.warn(`Cannot find environment for host name ${hostname}`);
        throwError("Cannot find environment for host name ${hostname}");
        alert(`Cannot find environment for host name ${hostname}`)
      }
      console.log("---this.envLoc---",this.envLoc)
      console.log("---this.envVar---",this.envVar)
      console.log("---this.configuration---",this.configuration)
    }
  
}