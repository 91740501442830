import { Component,Inject, OnInit , Input } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { Router , ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';

import { appService } from '@app/_services/all';
import { appThemeService } from './app-theme.service';

@Component({
  selector: 'app-theme',
  templateUrl: './app-theme.component.html',
  styleUrls: [ './app-theme.component.scss']
})
export class AppThemeComponent implements OnInit {
  
  fTitle:string = 'New Trigger';
  form: FormGroup;
  model: any;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];

  isDynamicComponent:boolean = false;

  settings:any = {};
  theme:any = {};
  localStorageName:string = 'app-theme-settings';
  themeClasses: any = {}
  requestMessage: string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formlyJsonschema: FormlyJsonschema,
    private appService:appService,
    private themeService:appThemeService,
    private route: ActivatedRoute,
    private router: Router
  ){    
    this.themeClasses     = this.themeService.themeClasses;
    this.localStorageName = this.appService?.settings.storageVariable || 'app-theme-settings';
    this.settings         = this.appService?.settings?.settings || {};
    this.theme            = this.settings?.theme || { wrapper : 'default' , header : 'default' , menu : 'top' };    
  }

  ngOnInit(): void {    
    this.form   = new FormGroup({   });
    this.fields = [];
    this.model  = { };
    this.setFormSchema();
    this.setFormModelValue();
  }

  setFormSchema():void {
    let schema:FormlyFieldConfig[] = [
      {
        className: 'section-label',
        template: '<div><strong>Layout:</strong></div><hr>',
      },    
      {
        fieldGroupClassName: 'row',
        fieldGroup: [          
          {
            className: 'col-4',
            type: 'select',
            name: 'wrapper',
            key: 'wrapper',
            defaultValue:'default',
            templateOptions: {
              label: 'Wrapper',
              placeholder: 'select',
              required: true,
              options: [                
                {label:'Default',value:'default'},
                {label:'Boxed',value:'boxed'},
                {label:'Full Width',value:'full'},
                {label:'Smartphone View',value:'smartphone'},
                {label:'Table view',value:'tablet'},
                {label:'Laptop View',value:'laptop'}
              ],
              change:(field, $event) => {  
                setTimeout(()=>{ this.onChange(field, $event) }, 10); 
              }
            }
          },
          {
            className: 'col-4',
            type: 'select',
            name: 'header',
            key: 'header',
            defaultValue:'default',
            templateOptions: {
              label: 'Header',
              placeholder: 'select',
              required: true,
              options: [
                {label:'Default',value:'default'},
                {label:'Static',value:'static'},
                {label:'Fixed',value:'fixed'}
              ],
              change:(field, $event) => {
                setTimeout(()=>{ this.onChange(field, $event) }, 10); 
              }
            }
          },
          {
            className: 'col-4',
            type: 'select',
            name: 'menu',
            key: 'menu',
            defaultValue:'top',
            templateOptions: {
              label: 'Main Menu',
              placeholder: 'select',
              required: true,
              options: [
                { label: 'Top', value: 'top' },
                {label:'Top-Lower',value:'top-sub'},
                {label:'Left-Side',value:'left'},
                {label:'Left-Side Mini',value:'left-mini'},
                {label:'Right-Side',value:'right'},
                {label:'Right-Side Mini',value:'right-mini'}
              ],
              change:(field, $event) => {
                setTimeout(()=>{ this.onChange(field, $event) }, 10); 
              }
            }
          },
        ],
      },       
      {
        className: 'section-label',
        template: '<div><strong>Options:</strong></div><hr>',
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-4',
            type: 'select',
            name: 'headerbg',
            key: 'headerbg',
            defaultValue:'top',
            templateOptions: {
              label: 'Header Background',
              placeholder: 'select',
              required: true,
              options: [                
                {label:'Primary',value:'primary'},
                {label:'Dark',value:'dark'},
                {label:'Light',value:'light'}
              ],
              change:(field, $event) => {
                setTimeout(()=>{ this.onChange(field, $event) }, 10); 
              }
            }
          },
          {
            className: 'col-4',
            type: 'input',
            name: 'midcontainerbg',
            key: 'midcontainerbg',
            defaultValue:'#ffffff',
            templateOptions: {
              label: 'Main container Background',
              type:'color',
              required: false,
              change:(field, $event) => {
                setTimeout(()=>{ this.onChange(field, $event) }, 10); 
              }
            }
          }
        ]
      }  
    ];
    this.fields = schema;
  }

  setFormModelValue():void{    
    this.model = this.themeService.getLocalStorageTheme()
  }

  onChange(field:FormlyFieldConfig, event:any):void{     
    
    let key:any       = field.key;   
    let values:any    = this.model;  
    let value:string  = values[key] || '';
    if(key=="wrapper"){   
      this.themeService.changeWrapperClass(value)
    }
    if(key=="header"){
      this.themeService.changeHeaderClass(value)
    }
    if(key=="menu"){
      this.themeService.changeMenuClass(value)
    }
    if(key=="headerbg"){
      this.themeService.changeHeaderBackground(value)
    }
    if(key=="midcontainerbg"){
      this.themeService.changeContainerBackground(value)
    }
    
  }

  onSubmit(): void{
    if(this.form.valid){     
      this.themeService.setLocalStorageTheme(this.form.value)
      this.requestMessage = "Save to your browser successfully";
    }else{
      this.form.updateValueAndValidity()
    }
  }

  onOriginal(): void{
    this.model = this.themeService.theme;
    this.themeService.setLocalStorageTheme(this.themeService.theme)
    this.requestMessage = "Original theme updated to your browser successfully";
  }

  onResetModel():void{
    this.options.resetModel();
  }

  OnInitialValue():void{
    this.options.updateInitialValue()
  }

}
