import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoaderService {

    private opened:boolean = false;
    private totalActive:number = 0;

    constructor(){} 

    public open(n:any = 8000):void {
        var self = this;
        this.opened = true;
        this.totalActive++;
        document.getElementById('globalLoading').classList.add('show');        
    }

    public close():void {
        this.totalActive--;
        if(this.totalActive<1){
            document.getElementById('globalLoading').classList.remove('show')
            this.opened = false;
        } 
        if(this.totalActive<0){
            this.totalActive = 0;
        }
    }

    public get isOpen():boolean{
        return this.opened;
    }

}