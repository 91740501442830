import { Injectable } from '@angular/core';
import { Router,  NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { AccountService , SettingsService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class RouteGuard  {

    routeSubscription: Subscription;
    configs:any;
    account:any;
    links:any = [];
    uniqLinks:any = [];

    constructor( 
      private router: Router,
      private accountService: AccountService ,
      private settingService:SettingsService
    ){
        this.routeSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
              let url:string = event.url.toLowerCase() || "";
              let isLoggedin:boolean = this.accountService.userValue.isAuthendicate || false;
              let authUrl:string[] = this.settingService.settings.navigation?.unAuthendicatedUrl || [];
              let validate:boolean = this.settingService.settings.navigation?.validate || false;   
              if(validate && !isLoggedin){
                let allowed = false;
                for(var i=0;i<authUrl.length;i++){
                  let aUrl = authUrl[i].toLowerCase();
                  if(url == aUrl){
                    allowed = true;
                  }else if(aUrl !="/" && (url.startsWith(aUrl) || aUrl.startsWith(url)) ){
                    allowed = true;
                  } else { }
                }  
                if(!allowed){
                  this.movetoHome()
                }              
              }
              this.updateActiveMenu(url)
            }
        }); 
    }

    private updateActiveMenu(cUrl:string):void {      
      document.getElementById('mainMenu').querySelectorAll('.nav-item').forEach((item)=>{
        item.classList.remove('active');
        let url = item.querySelector('.nav-link').getAttribute('routerlink');
        if (url && cUrl.toLowerCase() == url.toLowerCase()) {
          item.classList.add('active');
        }
      })
    }
  
    private movetoHome():void{
      this.router.navigate(['/']);    
    }
    
}