import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-job-round-trip',
  templateUrl: './job-round-trip.component.html',
  styleUrls: ['./job-round-trip.component.scss']
})
export class JobRoundTripComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
