import { NgModule , APP_INITIALIZER } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient , HTTP_INTERCEPTORS} from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule , DatePipe } from '@angular/common';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';

import { HeaderComponent } from '@app/coreComponents/header/header.component';
import { FooterComponent } from '@app/coreComponents/footer/footer.component';
import { HomeComponent } from '@app/coreComponents/home/home.component';

import { BreadCrumComponent } from '@app/coreComponents/breadcrum/breadcrum.component';
import { HelperComponent } from '@app/coreComponents/helper/helper.component';
import { LoaderComponent } from '@app/coreComponents/loader/loader.component';
import { ThemeSettingsComponent } from '@app/coreComponents/theme/theme-settings.component';
import { AppThemeModule } from '@app/coreComponents/theme/settings/app-theme.module';

// import { MsalV2Ang10Module } from '@app/authModules/msal-v1ang10/MsalV2Ang10.module';
//import { CognitoModule } from '@app/authModules/cognito/cognito.module';
import { AuthModule } from './authModules/authentication/cognito/auth-modules.module';

/*-------iimport-shared-components------------------*/
import { AlertComponent } from '@app/_components';
/*-------imports-services------------------*/
import { appService } from '@app/_services/all';
import { AppInitService } from '@app/_services/init.service';
import { EnvService } from '@app/_services/env.service';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/*-------imports-interceptors common to all workspace and projects---*/
// import { JwtInterceptor, ErrorInterceptor , fakeBackendProvider } from '@app/_helpers';

import { ErrorInterceptor  } from '@app/_interceptors/error.interceptor';
import { RouteGuard } from '@app/_interceptors/router.intercepter';

import { DashboardModule }  from '@app/pageModules/dashboard/dashboard.module';
import { JobModule } from '@app/pageModules/job-detail-view/job-detail-view.module';
import { AggregationModule } from '@app/pageModules/job-round-trip/job-round-trip.module';
import { BsDropdownModule,BsDropdownConfig } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    ThemeSettingsComponent, 
    BreadCrumComponent,
    HelperComponent,
    LoaderComponent,
    HomeComponent,
    HeaderComponent,
    AppComponent, 
    FooterComponent,
    AlertComponent
  ],
  imports: [
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    ToastrModule.forRoot(),
    CommonModule,
    NgSelectModule,
    FormsModule, 
    ReactiveFormsModule,
    /*-IMPORT AUTH MODULE AND OMIT IN ROUTER ORDER--*/
    // MsalV2Ang10Module,
    //CognitoModule,
    AuthModule,
    // AppThemeModule,
    /*--CORE MODULES--*/
    DashboardModule,
    JobModule,
    AggregationModule,
    BsDropdownModule  
  ],
  providers: [
    DatePipe,
    HttpClient,
    AppInitService,
    EnvService,
    appService,
    { provide: RouteGuard,  useClass: RouteGuard , multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER, multi: true, deps: [AppInitService],
      useFactory : (appInitService: AppInitService)  => { return (): Promise<any> => { return appInitService.Init()} }
    },
    {
      provide: APP_INITIALIZER , multi: true, deps: [EnvService],
      useFactory: (envService: EnvService) => () => envService.init()
    },
    BsDropdownConfig
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

