import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { User , authConfs } from '../_models';
@Injectable({ providedIn: 'root' })
export class AccountService {
    
    private env : any;
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    private authConfigSubjectt: BehaviorSubject<authConfs>;
    public authConfig: Observable<authConfs>;
    private userInfo:any ={};
    
    constructor( private router: Router, private http: HttpClient ) {
        this.env                = environment;        
        this.authConfigSubjectt = new BehaviorSubject<authConfs>(this.env.authConfig);    
        this.authConfig         = this.authConfigSubjectt.asObservable();   
        this.userInfo           = this.getUserInfo();     
        this.userSubject        = new BehaviorSubject<User>(this.userInfo);
        this.user               = this.userSubject.asObservable();        
    }

    public get authConf(): authConfs {
        return this.authConfigSubjectt.value;
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    private getUserInfo():any{
        var authDetails:any  = {}
        var detailStr:any    =  "";
        var userDetails      = {
            id:'',
            name:'',
            email:'',
            picture:'',
            expTime:0,
            loginTime:0,
            authType:'',
            provider:'',
            idToken:'',
            accessToken:'',
            isAuthendicate: false,
            roleDetails:null,
            accessDetails: null,
            otherDetails: null
        };
        if(this.authConf.authStorageLocation.toLowerCase()=="session"){
            detailStr = sessionStorage.getItem('authDetailsVariable')            
        }else{
            detailStr = localStorage.getItem('authDetailsVariable')  
        }
        authDetails = JSON.parse(detailStr);
        if(authDetails){
            if(authDetails.sub){
                userDetails.id = authDetails.sub
            }
            if(this.authConf.authType.toLowerCase()=="cognito"){
                userDetails.provider = "aws";
                userDetails.authType = "cognito";
                if(authDetails.picture){
                    userDetails.picture = authDetails.picture
                }
                if(authDetails.email){
                    userDetails.email = authDetails.email
                }
                if(authDetails.name){
                    userDetails.name = authDetails.name
                }
                if(authDetails.exp){
                    userDetails.expTime = authDetails.exp;
                }
                if(authDetails.iat){
                    userDetails.loginTime = authDetails.iat;
                }
                if(authDetails.identities && authDetails.identities.providerName){
                    userDetails.provider = authDetails.identities.providerName
                }
                if(authDetails.identities){
                    userDetails.provider = authDetails.identities[0].providerName
                }
                if(authDetails.id_token){
                    userDetails.idToken = authDetails.id_token;
                }
                if(authDetails.access_token){
                    userDetails.accessToken = authDetails.access_token;
                }
                if(userDetails.name==''){
                    var userEmail:string  = userDetails.email;
                    var nameSplit:any = userEmail.split("@");
                    if(nameSplit.length>0){
                        userDetails.name = nameSplit[0];
                    }
                }
            }
            if(this.authConf.authType.toLowerCase()=="azure" || this.authConf.authType.toLowerCase()=="msal"){
                userDetails.provider = "microsoft";
                userDetails.authType = "azure";
                if(authDetails.picture){
                    userDetails.picture = authDetails.picture
                }
                if(authDetails.email){
                    userDetails.email = authDetails.email
                }else if(authDetails.preferred_username){
                    userDetails.email = authDetails.preferred_username
                }            
                if(authDetails.name){
                    userDetails.name = authDetails.name
                }
                if(authDetails.exp){
                    userDetails.expTime = authDetails.exp;
                }
                if(authDetails.iat){
                    userDetails.loginTime = authDetails.iat;
                }
                if(authDetails.id_token){
                    userDetails.idToken = authDetails.id_token;
                }
                if(authDetails.access_token){
                    userDetails.accessToken = authDetails.access_token;
                }
                if(userDetails.name==''){
                    var userEmail:string  = userDetails.email;
                    var nameSplit:any = userEmail.split("@");
                    if(nameSplit.length>0){
                        userDetails.name = nameSplit[0];
                    }
                }
            }
            if(userDetails.email){
                userDetails.isAuthendicate = true;
            }
        }
        return userDetails;
    }
    	
	private clearAccount():void{
		sessionStorage.clear();
		localStorage.clear();
	}

}
