import { BrowserModule } from '@angular/platform-browser';
import { NgModule , ModuleWithProviders } from '@angular/core';

import { AuthModulesRoutingModule } from './auth-modules-routing.module';
import { AuthModulesComponent } from './auth-modules.component';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';

import { TokenResolverService } from './resolver/token-resolver.service';

const providers:any = [TokenResolverService]

@NgModule({
  declarations: [
    AuthModulesComponent
  ],
  imports: [
    AuthModulesRoutingModule,
    CommonModule,
    HttpClientModule
  ],
  providers: providers,
  bootstrap: [AuthModulesComponent]
})
export class AuthModule { }

