import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/_helpers/auth.guard';
import { JobDetailViewComponent } from './job-detail-view.component';


const routes: Routes = [
  {
    path: 'jobs', component: JobDetailViewComponent, canActivate: [AuthGuard],
    children: [ ]
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false    })
  ],
  exports: [RouterModule]
})
export class JobRoutingModule { }
