import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';

import { AwsCognitoService } from './service/aws-cognito.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-modules',
  templateUrl: './auth-modules.component.html',
  styleUrls: ['./auth-modules.component.scss']
})
export class AuthModulesComponent implements OnInit {

  tokenDetails: any = null;
  token: any = null;

  constructor(
    private router: Router,
    private authSerice: AwsCognitoService
  ) { 
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const code: any = urlParams.get('code') || null;
    let cUrl = this.router.url.split('?')[0];
    if(cUrl && cUrl.toLowerCase().startsWith("/auth/login") ){ 
      this.authSerice.loginUser();
    }else if(cUrl && cUrl.toLowerCase().startsWith("/auth/authendicate") ){      
      setTimeout(() => { 
        this.checkUserLogin(code);
      }, 8000);
    }else if(cUrl && cUrl.toLowerCase().startsWith("/auth/logout") ){
      this.removeAuthDetails();      
    }else{
      //this.router.navigate(['/']);
      window.location.href = "/";
    }

  }

  ngOnInit(): void { 
  }

  checkUserLogin(code:any = null): void{
    this.token = localStorage.getItem('authDetailsVariable');
    this.tokenDetails = JSON.parse(this.token);
    if(this.token){
      //this.router.navigate(['/']);
      window.location.href = "/";
    }else{

    }
  }

  removeAuthDetails(): void{
    localStorage.removeItem('authCallbackResponse');
    localStorage.removeItem('authTokenVariable');
    localStorage.removeItem('authDetailsVariable');
    localStorage.removeItem('authType');
    localStorage.removeItem('authDetails');
    localStorage.removeItem('authResponse');    
    // token
    // azure
    //this.router.navigate(['/']);
    window.location.href = "/";
  }

}
