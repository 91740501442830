import { Injectable, Inject } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';

import { appService } from '@app/_services/all';


@Injectable({ providedIn: 'root' })
export class appThemeService {

    settings:any = {};
    theme:any = {};
    localStorageName:string = 'app-theme-settings';
    themeClasses:any = {
      wrapper : {
        'default' : ['page-container-default'],
        'boxed' : ['page-container-default'],
        'full' : ['page-container-default'],
        'smartphone' : ['page-container-default'],
        'tablet' : ['page-container-default'],
        'laptop' : ['page-container-default']
      },
      header:{
        'default' : ['default-header'],
        'static' : ['static-header'],
        'fixed' : ['fixed-header']
      },
      menu:{
        'top': ['top-nav'],
        'top-sub':['top-nav','mini-nav'],
        'left' : ['left-nav'],
        'right' : ['right-nav'],
        'left-mini' : ['left-nav','mini-nav'],
        'right-mini' : ['right-nav','mini-nav']
      },
      headerbg :{
            'primary' : ['navbar-dark','bg-primary'],
            'dark' : ['navbar-dark','bg-dark'],
            'light' : ['navbar-light','bg-light']
      },
      midcontainerbg:'#fff'
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private appService:appService
    ){
        // console.log(this.appService.settings.settings)
        this.localStorageName = this.appService?.settings.storageVariable || 'app-theme-settings';
        this.settings         = this.appService?.settings?.settings || {};
        this.theme            = this.settings?.theme || { wrapper : 'default' , header : 'default' , menu : 'top' };        
        this.initLocalStorageTheme();        
    }

    public initLocalStorageTheme():void{
      let theme: any = this.getLocalStorageTheme();
      if (!theme) {
        this.setLocalStorageTheme( this.theme )
      }        
    }
    
    public getLocalStorageTheme(): any{
      //Object.keys(localStorage).forEach(dataKey => {});
      let theme: any = localStorage.getItem(this.localStorageName) || null;    
      if(theme){
        theme = JSON.parse(theme)
      }
      return theme;
    }
    
    public setLocalStorageTheme(theme:any):void{
        if(theme){
          localStorage.setItem(this.localStorageName, JSON.stringify(theme) )
        }     
    }
    
    public changeWrapperClass(value:string):void{
        for (const [xkey, xvalue] of Object.entries(this.themeClasses.wrapper)) {
          let classList = this.themeClasses.wrapper[xkey] || [];
          for(let i:number=0;i<classList.length;i++){
            this.document.body.classList.remove(classList[i])
          }
        }
        let nClassList = this.themeClasses.wrapper[value] || []
        for(let i:number=0;i<nClassList.length;i++){
          this.document.body.classList.add(nClassList[i])
        }
    }
    
    public changeHeaderClass(value:string):void{
      let ele:Element = this.document.getElementById('pageContainer');
      for (const [xkey, xvalue] of Object.entries(this.themeClasses.header)) {
        let classList = this.themeClasses.header[xkey] || [];
        for(let i:number=0;i<classList.length;i++){
          ele.classList.remove(classList[i])
        }
      }
      let nClassList = this.themeClasses.header[value] || []
      for(let i:number=0;i<nClassList.length;i++){
        ele.classList.add(nClassList[i])
      }
    }
    
    public changeMenuClass(value:string):void{
      let ele:Element = this.document.getElementById('pageContainer');
      for (const [xkey, xvalue] of Object.entries(this.themeClasses.menu)) {
        let classList = this.themeClasses.menu[xkey] || [];
        for(let i:number=0;i<classList.length;i++){            
          ele.classList.remove(classList[i])
        }
      }
      let nClassList = this.themeClasses.menu[value] || []
      for(let i:number=0;i<nClassList.length;i++){
        ele.classList.add(nClassList[i])
      }

      let nClass = 'dropdown';
      if(value.toLowerCase()=='top'){
        nClass = 'dropdown';
      }else if(value.toLowerCase()=='left'){
        nClass = 'dropright';
      }else if(value.toLowerCase()=='right'){
        nClass = 'dropleft';
      }else if(value.toLowerCase()=='left-mini'){
        nClass = 'dropright';
      }else if(value.toLowerCase()=='right-mini'){
        nClass = 'dropleft';
      }else if(value.toLowerCase()=='down'){
        nClass = 'dropup';
      }else{
        nClass = 'dropdown';
      }
      this.document.getElementById('navbarTogglerMain').querySelectorAll('.nav-item.dropdown').forEach((item)=>{
        item.classList.remove('dropdown');
        item.classList.remove('dropup');
        item.classList.remove('dropright');
        item.classList.remove('dropleft');
        item.classList.add(nClass);
      })
      this.document.getElementById('navbarTogglerMain').querySelectorAll('.nav-item.dropup').forEach((item)=>{
        item.classList.remove('dropdown');
        item.classList.remove('dropup');
        item.classList.remove('dropright');
        item.classList.remove('dropleft');
        item.classList.add(nClass);
      })
      this.document.getElementById('navbarTogglerMain').querySelectorAll('.nav-item.dropright').forEach((item)=>{
        item.classList.remove('dropdown');
        item.classList.remove('dropup');
        item.classList.remove('dropright');
        item.classList.remove('dropleft');
        item.classList.add(nClass);
      })
      this.document.getElementById('navbarTogglerMain').querySelectorAll('.nav-item.dropleft').forEach((item)=>{
        item.classList.remove('dropdown');
        item.classList.remove('dropup');
        item.classList.remove('dropright');
        item.classList.remove('dropleft');
        item.classList.add(nClass);
      })
    }   

    public changeHeaderBackground(value:string){
      let ele:Element = this.document.querySelector('.header .navbar');
      for (const [xkey, xvalue] of Object.entries(this.themeClasses.headerbg)) {
        let classList = this.themeClasses.headerbg[xkey] || [];
        for(let i:number=0;i<classList.length;i++){            
          ele.classList.remove(classList[i])
        }
      }
      let nClassList = this.themeClasses.headerbg[value] || []
      for(let i:number=0;i<nClassList.length;i++){
        ele.classList.add(nClassList[i])
      }
    }

    public changeContainerBackground(value:string){
      this.document.documentElement.style.setProperty('--primaryContainerBg', value);
    }

    public applyInitialTheme():void{
      let theme:any         = this.getLocalStorageTheme();
      if(theme.wrapper){
        this.changeWrapperClass(theme.wrapper)
      }
      if(theme.header){
        this.changeHeaderClass(theme.header)
      }
      if(theme.menu){
        this.changeMenuClass(theme.menu)
      }

      if(theme.headerbg){
        this.changeHeaderBackground(theme.headerbg)
      }      
    }

}