import { Component, OnInit,ViewChild } from '@angular/core';

import { smDataService } from '@app/app.service';
import { appService } from '@app/_services/all';
import {Table} from 'primeng/table';
import {FilterMatchMode, PrimeNGConfig} from 'primeng/api';
import  { UtilService } from '@app/_services/util.service';


import * as Highcharts from 'highcharts';
import highcharts3D from 'highcharts/highcharts-3d';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsSeriesLabel from 'highcharts/modules/series-label';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import { ActivatedRoute, Router } from '@angular/router';


highcharts3D(Highcharts);
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
HighchartsBoost(Highcharts);
HighchartsNoData(Highcharts);
HighchartsSeriesLabel(Highcharts);
HighchartsAccessibility(Highcharts);

declare interface Custom extends Highcharts.PointOptionsObject {
  customProperty: any;
}

@Component({
  selector  : 'app-job-detail-view',
  templateUrl : './job-detail-view.component.html',
  styleUrls : ['./job-detail-view.component.scss']
})
export class JobDetailViewComponent implements OnInit {
  jobs: any;
  client: any[] = [];
  jobsList: any[] = [];
  searchType: any[] = [];
  searchEngines: any[] = [];

  dateFromSelected: any = null;
  dateToSelected: any = null;
  jobSelected: any = null;
  clientSelected: any = null;
  typeSelected: any = null;
  engineSelected: any = null;
  clientname: any;
  jobname: any;
  searchtype: any;
  searchengine:any;
  fromdate:any;
  todate:any;
  filedate:any;
  jobdate: string;

  loading:boolean = false;

  loadingTable1:boolean = false;
  loadingTable2:boolean = false;
  loadingChart1:boolean = false;

  chart1: Highcharts.Chart = null;

  @ViewChild('dt') table: Table | any;

  onChangeLoad: boolean = false;
  roundTrip: any;
  jobList: any;

  selectedCars: any;
  tripId: any;
  providerId: any;
  clientChart: any;
  jobChart: any;
  jobChartname: any;
  jobListPage: any = 1;
  jobListSize: any = 1000;
  baJobsList: any;
  jobStatus: any ="inprogress";
  // tripClient: any;
  jobProgress:boolean = true;
  clientFirst: any;
  clientRemaining: any;
  // selJobChartname: string;
  // selJobname: string;
  inprogressSel:boolean = false;
  completedSel:boolean =  false;

  constructor(
    private dataService : smDataService,
    private appService : appService,
    private primengConfig: PrimeNGConfig,
    private utilService:UtilService,
    private activeRoute:ActivatedRoute,
    private router:Router
  ){

  }

  ngOnInit(): void {    
    this.refreshJobsList();
    this.getClient();
    this.getJobs();
    this.getSearchType();
    this.getSearchEngine();
    this.jobsChart();
    this.jobdate = new Date().toISOString().split('T')[0];

    this.activeRoute.queryParams.subscribe(params=>{
      let id =this.router.url.substring(this.router.url.lastIndexOf("/") + 1);
    })
   
  }

  refreshJobsList(): void {
    let data = {
      "type": {
        "value":"getjobdetail",
        "matchMode":"equals"
      }
    }
    if(this.filedate != null){
      data['filedate'] = {
        "value" :this.filedate,
        "matchMode"   :"between"
      }
    }
    if(this.clientname != null){
      data['client'] = {
        "value" :this.clientname,
        "matchMode"   :"equals"
      }
    }
    if(this.jobname != null){
      let selJobname :any = ""
      this.jobname.forEach((element:any) => {
        if(selJobname){
          selJobname += "," + element['jobname'];            
        }else{
          selJobname += element['jobname'];
        }
      });
      data['jobname'] = {
        "value" :selJobname,
        "matchMode"   :"in"
      }
    }
    if(this.searchtype != null){
      data['searchtype'] = {
        "value" :this.searchtype,
        "matchMode"   :"equals"
      }
    }
    if(this.searchengine != null){
      data['searchengine'] = {
        "value" :this.searchengine,
        "matchMode"   :"equals"
      } 
    }
    if(this.jobStatus){      
      if(this.jobStatus == "inprogress"){
        this.jobProgress = true;
      }else if(this.jobStatus == "completed"){
        this.jobProgress = false;
      }
      data['jobstatus'] = {
        "value" :this.jobStatus,
        "matchMode"   :"equals"
      } 
    }
    this.loadingTable1 = true;
    this.dataService.getJobs( { filters: JSON.stringify(data), page:this.jobListPage, size:this.jobListSize } ).subscribe( (res:any) => {
       this.jobs  = res.jobs;
       if(res.jobs.length){
        this.tripId = res.jobs[0].jobid;        
        this.clientFirst = Array.from(res.jobs[0].jobname)[0];
        this.clientRemaining = res.jobs[0].jobname.substring(1, res.jobs[0].jobname.length)
        this.getRoundtrip(this.tripId)
       }else{
        this.roundTrip = [];
       }
       this.loadingTable1 = false;
    });
  }

  getClient(){
    this.dataService.getClient().subscribe((res:any) => {
      this.client = res.providers
      })
  }

  getJobs(name:any='', clientName:any=''){
    let dataList:any = {"type":{"value":"getjob","matchMode":"equals"}};
    let filter:any = {};
    if(clientName){
      dataList['client'] = {"value":clientName,"matchMode":"equals"}
    }
    filter['filters'] =  JSON.stringify(dataList)
    this.dataService.getJobslist(filter).subscribe((res:any) => {
      if(name == 'jobFilter'){
        this.jobsList = res.jobs;
      }else if(name == 'jobChart'){
        this.baJobsList = res.jobs;
      }else{
        this.baJobsList = res.jobs;
        this.jobsList = res.jobs;
      }
    })
  }

  getRoundtrip(tripId:any){     
    let data = {
      "type":{        
        "value":"getjobroundtrip",
        "matchMode":"equals"
      },
      "parentjobid":{
        "value": tripId,
        "matchMode":"equals"
      }
    }
    this.loadingTable2 = true;
    this.dataService.getJobs( { filters: JSON.stringify(data) } ).subscribe( (res:any) => {
      this.roundTrip  = res.jobs;
      this.loadingTable2 = false;
    });    
  }

  getSearchType(){
    this.dataService.getSearchType().subscribe((res:any) => {
    this.searchType = res.searchTypes
    })
  }

  getSearchEngine(){
    this.dataService.getSearchEngine().subscribe((res:any) =>{
    this.searchEngines = res.searchEngines
    })
  }

  updateJobsFilter(data:any,type:any,value:any=null){
    if(data != undefined){
      if(type == "date" ){
        var date1 = new Date(data[0]);
        let datefrom = JSON.stringify(date1)
        this.fromdate = datefrom.slice(1,11)

        var date2 = new Date(data[1]);
        let dateto = JSON.stringify(date2)
        this.todate = dateto.slice(1,11)
        this.filedate =  this.fromdate + ',' + this.todate
      }
      if(type == "client"){
        if(data['provider']){
          this.clientname =  data['provider'];
          this.providerId = data['provider_id'];
        }
        this.getJobs('jobFilter', this.clientname );
        this.jobname = [];
      }
      if(type == "jobname"){
        this.jobname =  data;
      }
      if(type == "searchtype"){
        this.searchtype =  data['search_type'];
      }
      if(type == "searchengine"){
        this.searchengine =  data['searchtype'];
      }
      if(type == "jobStatus"){
        this.jobStatus = value;
          // if(value == "inprogress"){
          //   this.inprogressSel = !this.inprogressSel
          // }else if(value == "completed"){
          //   this.completedSel = !this.completedSel
          // }
          // if(this.inprogressSel && this.completedSel){
          //   this.jobStatus = "";
          // }else if(this.inprogressSel){         
          //   this.jobStatus = "inprogress";
          // }else if(this.completedSel){         
          //   this.jobStatus = "completed";
          // }else {
          //   this.jobStatus = "";
          // }
        this.refreshJobsList();
      }
    }
    // else{
    //   this.refreshJobsList();
    // }
    
    if(this.onChangeLoad){
        this.refreshJobsList();
    }
  }

  clearClientJob(name:any){
    this.getJobs(name);
    if(name == 'jobFilter'){
      this.jobname = [];
    }else if(name == 'jobChart'){
      this.jobChartname = [];
      // this.selJobChartname = "";
      this.jobsChart();
    }else{
      this.jobChartname = [];
      // this.selJobChartname = "";
      this.jobname = [];
    }
  }

  searchJobList():void{
    this.refreshJobsList();
  }

  public clearJobFilters():void{

    this.clientname = null;
    this.jobname = null;
    this.searchtype = null;
    this.searchengine = null;
    // this.clientname = [];
    this.filedate = null;
    this.jobdate = new Date().toISOString().split('T')[0];
    this.getJobs('jobFilter');
    this.refreshJobsList();
    
  }

  public jobsChart(){
    let data = {
      "type": {
        "value":"getfillrate",
        "matchMode":"equals"
      }
      
    }

    if(this.clientChart){
      data['client'] = {
        "value" :this.clientChart,
        "matchMode"   :"IN"
      }
    }

    if(this.jobChartname){
      let selJobChartname:any = "";
      this.jobChartname.forEach((element:any) => {
        if(selJobChartname){
        selJobChartname += "," + element['jobname'];            
        }else{
        selJobChartname += element['jobname'];
        }
      });
      data['jobname'] = {
        "value" :selJobChartname,
        "matchMode"   :"in"
      }
    }

    this.toggleChartLoading();
    this.dataService.getJobs( { filters: JSON.stringify(data) }).subscribe((res:any) => {
      this.jobList = res.jobs;
      this.getjobChartdata(this.jobList);      
    })

  }
  

  renderjoblistChart(data:any, categoryVal:any){
    //let monoColors = this.getMonochromeColors('#5A9CE3');
    this.chart1 = Highcharts.chart('lineChart', {        
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false
      },
      //colorAxis: {},
      title: {
          text: ''
      },  
      subtitle: {
          text: ''
      },
      credits: {
        enabled: false
      },  
      yAxis: {
          title: {
              text: 'Fill Rate %'
          }
      },  
      xAxis: {
        categories: categoryVal         
      },  
      legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
      }, 
      colors: ['#6CF', '#39F', '#06C', '#036', '#000'], 
      plotOptions: {
        
        series: {
          label: {
              connectorAllowed: false,
          }            
        }
      },  
      series: data,  
      responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  legend: {
                      layout: 'horizontal',
                      align: 'center',
                      verticalAlign: 'bottom'
                  }
              }
          }]
      }  
    });
    this.chart1.hideLoading();
    this.loadingChart1 = false;
  }

  getjobChartdata(jobList:any){
      let result = [];
      let categories:any;
      for(let i=0; i< jobList.length; i++){
        let jobname     = jobList[i].jobname;
            categories  = Object.keys(jobList[i]);          
        let sData       = Object.values(jobList[i]);
        const index     = categories.indexOf('jobname', 0);
        if (index > -1) {
          categories.splice(index, 1);
        }
        const index1 = sData.indexOf(jobname, 0);
        if (index1 > -1) {
          sData.splice(index1, 1);
        }
        let lineColor = this.getPosMonochromeColor('#5A9CE3',i)
        result.push({'type':'line', 'name':jobname, colorByPoint: false,'data':sData  });
      }
      this.renderjoblistChart(result, categories);
  }

  clearChart(){
    for (let index = 0; index < this.chart1.series.length; index++) {
      this.chart1.series[index].remove();
    }
    this.chart1.redraw(true);
  }

  toggleChartLoading(): void{
    if(this.chart1){
      if(this.loadingChart1){
        this.chart1.hideLoading();
        this.loadingChart1 = false;
      }else{
        this.chart1.showLoading();
        this.loadingChart1 = true;
      }
    }
  }

  renderRoundclick(id:any, client:any){
    this.tripId =  id;
    this.clientFirst = Array.from(client)[0];
    this.clientRemaining = client.substring(1, client.length)
    this.getRoundtrip(id);
  }

  searchChart(data:any,type:any){
    if(data !== undefined){
      if(type == "client"){
        this.clientChart = data['provider'];
        this.providerId = data['provider_id'];
        this.getJobs('jobChart', this.clientChart);
        this.jobChartname = [];
      }
      if(type == "job"){
        this.jobChartname = data;
      }
    }
    this.jobsChart();   
  }  
 
  viewJobList(job:any){
    this.router.navigate(['aggregation', job.jobid]);
  }

  getMonochromeColors(color:any, ln:number = 10) {
    var colors = [];
			var base = color;
			for (var i = 0; i < ln; i += 1) {
				colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());
			}	
			return colors;
  }

  getPosMonochromeColor(color:any, ln:number = 0) {
    var nColor = color;
    var base = color;
    for (var i = 0; i < ln; i += 1) {
      nColor = Highcharts.color(base).brighten( ( i - 3) / 2 ).get();
    }	
    return nColor;
  }

}
 