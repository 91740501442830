<div id="globalLoading" class="globalLoading" >
	<div class="loader" style="display:none;"></div>
	<div class="lds-ripple" style="display:none;">
		<div></div>
		<div></div>
	</div>
	<div class="lds-facebook">
		<div></div>
		<div></div>
		<div></div>
	</div>
	<div class="lds-grid" style="display:none;">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</div>