<header class="pt-2 pb-2 px-3">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="d-flex  mt-auto mb-auto align-items-center justify-content-between">
                    <a href="javascript:;">
                        <img src="../../../assets/img/logo.svg" alt="logo" width="80%">
                    </a>
                    <div class="menu">
                        <nav class="navbar navbar-white bg-white navbar-expand-sm p-0">
                            <a class="navbar-brand d-none" href="javascript:;">
                                Menu
                            </a>
                            <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbar-list-2" aria-controls="navbarNav" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <!-- <div class="collapse navbar-collapse" id="navbar-list-2">
                                <ul class="navbar-nav ml-auto">
                                    <li  [ngClass]="router.url == '/home'? 'nav-item active': 'nav-link'">
                                        <a class="nav-link"  routerLink="home"><i class="fe fe-home"></i> Home </a>
                                    </li>
                                    <li [ngClass]="router.url == '/jobs'? 'nav-item active': 'nav-link'">
                                        <a class="nav-link" routerLink="/jobs"><i class="fe fe-briefcase"></i> Jobs</a>
                                    </li>
                                    <li [ngClass]="router.url == '/aggregation'? 'nav-item active': 'nav-link'">
                                        <a class="nav-link" routerLink="aggregation"><i class="fe fe-cpu"></i> Aggregation</a>
                                    </li>
                                </ul>
                            </div> -->

                            <div class="collapse navbar-collapse" id="navbar-list-2" *ngIf="isLogged">
                                <ul class="navbar-nav ml-auto">
                                    <li class="nav-item" [ngClass]="router.url == '/dashboard' || router.url == '/' ? 'active' : ''" [routerLinkActive]="['active']">
                                        <a class="nav-link" [routerLink]="['dashboard']"><i class="fe fe-home"></i> Home </a>
                                    </li>
                                    <li class="nav-item " [routerLinkActive]="['active']">
                                        <a class="nav-link" [routerLink]="['jobs']"><i class="fe fe-briefcase"></i> Jobs</a>
                                    </li>
                                    <li class="nav-item " [routerLinkActive]="['active']">
                                        <a class="nav-link" [routerLink]="['aggregation']" ><i class="fe fe-cpu"></i> Aggregation</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                    </div>
                    <div class="user-menu  d-flex align-items-center" *ngIf="isLogged">
                        <div class="dropdown username-url">
                            <a href="javascript:;" class="ml-4 dropdown-toggle" data-toggle="dropdown">
                             <img [src]="userPic? userPic : '../../../assets/img/user.png'" width="25" class="mr-2" style="border-radius:50%;" > {{userName}}
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <!-- <a class="dropdown-item" href="#"><i class="fe fe-settings mr-2"></i> Settings</a> -->
                              <a class="dropdown-item" [routerLink]="['auth/logout']"><i class="fe fe-log-out mr-2"></i> Log Out</a>
                            </div>
                          </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</header>