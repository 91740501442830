import { NgModule , CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ProgressbarModule,ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { JobRoutingModule } from './job-detail-view-routing.module';
import { JobDetailViewComponent } from './job-detail-view.component';
import { TableModule } from 'primeng/table';
import { ThousandSuffixesPipes } from '@app/_helpers/numberconv_pipe';
import { NgSelectModule, NgOption} from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';


@NgModule({
  declarations: [
    JobDetailViewComponent,
    ThousandSuffixesPipes
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    JobRoutingModule,
    ProgressbarModule,
    TableModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
    ProgressbarConfig
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class JobModule { }
