import { 
  ApplicationRef, Component , ViewEncapsulation ,
  ComponentRef , ComponentFactoryResolver, TemplateRef ,
  Injector , Renderer2,  OnInit , AfterViewInit , ViewChild , ElementRef
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { appService } from '@app/_services/all';
import { appThemeService } from './settings/app-theme.service';
import { AppThemeComponent } from './settings/app-theme.component';


@Component({
  selector: 'app-theme',
  templateUrl: './theme-settings.component.html',
  styleUrls: ['./theme-settings.component.scss']
})
export class ThemeSettingsComponent implements OnInit , AfterViewInit {

  @ViewChild('modalContent', {static : true} ) myModal : TemplateRef<any>;

  modalOptions:any = {
    size: 'lg',
    centered: false,
    scrollable: true,
    windowClass: 'dark-modal',
    backdropClass: 'light-blue-backdrop',
    modalDialogClass: 'dark-modal'
  }

  constructor(
    private element: ElementRef,
    private themeService:appThemeService,
    private injector: Injector, 
    private renderer:Renderer2,
    private applicationRef: ApplicationRef, 
    private componentFactoryResolver: ComponentFactoryResolver,
    private modalService: NgbModal
  ) { 
    
  }

  ngOnInit(): void {
    this.themeService.applyInitialTheme();
  }
  
  ngAfterViewInit(): void {
    this.themeService.applyInitialTheme();
  }

  upDateTheme(){
    this.openSettings();
  }

  openSettings(){
    const divId = 'dynamicDiv';
    const modealRef = this.modalService.open(this.myModal, this.modalOptions );
    const dynamicModalPopup  = document.createElement('popup-component');
    const componentFactory   = this.componentFactoryResolver.resolveComponentFactory(AppThemeComponent);
    const popupComponentRef:ComponentRef<any>  = componentFactory.create(this.injector, [], dynamicModalPopup);
    popupComponentRef.instance.isDynamicComponent = true;
    this.applicationRef.attachView(popupComponentRef.hostView);
    const refEle:Element = document.getElementById(divId);
    refEle.innerHTML = '';
    refEle.appendChild(dynamicModalPopup);
  }
  
}
