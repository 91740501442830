<section class="pt-3 pb-3 px-3">
   <div class="container-fluid">
      <div class="row breadcrumb-h">
         <div class="col-12">
            <div class="d-flex align-items-center justifiy-content-center">
               <h4 class="heading"><i class="fe fe-briefcase"></i> Job Detailed View</h4>
            </div>
         </div>
      </div>
   </div>
 </section>
 <section class=" px-3">
   <div class="container-fluid">
      <div class="row form-row">
         <div class="col-12">
            <div class="row">
               <div class="col-12">
                  <h3 class="title"><span>J</span>ob List</h3>
               </div>
            </div>
         </div>
         <div class="col-12">
            <div class="card p-3">
               <!-- <form > -->
               <div class="form-row">
                  <div class="col mt-auto mb-auto">
                     <div class="input-group mb-0" style="width: 200px;">
                        <label class="d-block w-100" >Date Range</label>
                        <input type="text" 
                        placeholder="Select Date"
                        class="form-control"
                        [(ngModel)] = "jobdate" 
                        name="filedate"
                        id="filedate"
                        (ngModelChange)="updateJobsFilter($event,'date')"
                        bsDaterangepicker placement="right">
                        <div class="input-group-append">
                           <span class="input-group-text" ><i class="fe fe-calendar"></i></span>
                        </div>
                     </div>
                  </div>
                  <div class="col mt-auto mb-auto">
                     <div class="form-group" >
                        <label >Client</label>
                        <ng-select  placeholder="Select Client" [items]="client" 
                        bindLabel="provider" name="clientname"    (change)="updateJobsFilter($event,'client')"
                        (clear) ="clearClientJob('jobFilter')"
                        [(ngModel)]="clientname"> </ng-select>
                     </div>
                  </div>
                  <div class="col mt-auto mb-auto" >
                     <div class="form-group" style="width: 311px;">
                        <label >Job</label>
                        <ng-select placeholder="Select Job"
                        (change)="updateJobsFilter($event,'jobname')" [multiple]="true" [closeOnSelect]="false"
                        id="jobid" name="jobname" [items]="jobsList" bindLabel="jobname"  
                        [(ngModel)]="jobname"  >
                           <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                             <div class="ng-value" *ngFor="let item of items | slice:0:1">
                               <span class="ng-value-label">{{item.jobname}}</span>
                               <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">x</span>
                             </div>
                             <div class="ng-value" *ngIf="items.length > 1">
                               <span class="ng-value-label">{{items.length - 1}} more...</span>
                             </div>
                           </ng-template>
                         </ng-select>
                      </div>
                  </div>
                  <div class="col mt-auto mb-auto">
                     <div class="form-group" style="width: 142px">
                        <label >Search Type</label>
                        <ng-select placeholder="Select Search Type"  (change)="updateJobsFilter($event,'searchtype')"
                        id="searchtype" name="searchtype" [(ngModel)]="searchtype"  [items]="searchType" bindLabel="search_type"	
                        ></ng-select>
                     </div>
                  </div>
                  <div class="col mt-auto mb-auto">
                     <div class="form-group" style="width: 160px;">
                        <label >Search Engines</label>
                        <ng-select placeholder="Select Search Engines"  (change)="updateJobsFilter($event,'searchengine')"   [items]="searchEngines" bindLabel="searchtype"	
                        bindId="searchengine_id" [(ngModel)] = "searchengine"  ></ng-select>
                     </div>
                  </div>
                  <div class="col mt-auto mb-auto">
                     <div class="form-group ">
                        <div class="d-flex submit">
                           <button type="button" class="btn btn-primary btn-sm w-100"  (click) ="searchJobList()">Search</button>
                           <button type="button" class="btn btn-default btn-sm w-100 ml-2"  (click) ="clearJobFilters()">Clear</button>
                        </div>
                     </div>
                  </div>
                  <div class="col mt-auto mb-auto">
                     <div class="form-group">
                        <label >Job Status</label>
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                           <label class="btn btn-outline-primary active">
                           <!-- <input type="checkbox" [checked]="inprogressSel" name="inprogress" id="inprogress" (click)="updateJobsFilter($event,'jobStatus','inprogress')" autocomplete="off" checked> Inprogress -->
                           <input type="radio"  name="inprogress" id="inprogress" value="inprogress"  (click)="updateJobsFilter($event,'jobStatus','inprogress')" autocomplete="off"> Inprogress
                          </label>
                           <label class="btn btn-outline-primary">
                           <!-- <input type="checkbox" [checked]="completedSel" (click)="updateJobsFilter($event,'jobStatus','completed')" name="completed" id="completed" autocomplete="off"> Completed -->
                           <input type="radio" (click)="updateJobsFilter($event,'jobStatus','completed')" value="completed" name="completed" id="completed" autocomplete="off"> Completed
                          </label>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- </form> -->
            </div>
         </div>
      </div>
   </div>
 </section>
 <section class="px-3">
   <div class="container-fluid">
      <div class="row mt-3 mb-3">
         <div class="col-12">
            <div class="card p-0">
               <div class="table-responsive">
                  <p-table #dt 
                  [value]="jobs" 
                  dataKey="id" 
                  styleClass="p-datatable-customers" 
                  [loading]="loadingTable1"
                  [rowHover]="true" 
                  [rows]="10" 
                  [showCurrentPageReport]="true" 
                  [paginator]="true" 
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
                  [filterDelay]="0"  
                  [scrollable]="true" 
                  scrollHeight="375px">
                  <ng-template pTemplate="header">
                     <tr >
                        <th colspan="8" class="top-heading"><span class="text-center w-100"><b>Input Stats</b></span></th>
                        <th colspan="14" class="top-heading"><span class="text-center w-100"><b>Agg Parser View</b></span></th>
                     </tr>
                     <tr >
                        <th pSortableColumn="jobname">
                           Job Name 
                           <p-sortIcon field="jobname"></p-sortIcon>
                        </th>
                        <th pSortableColumn="client">
                           Client 
                           <p-sortIcon field="client"></p-sortIcon>
                        </th>
                        <th pSortableColumn="filedate">
                           File Date 
                           <p-sortIcon field="filedate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="no_of_files">
                           No of files
                           <p-sortIcon field="no_of_files"></p-sortIcon>
                        </th>
                        <th pSortableColumn="totalrecords">
                           No of Records
                           <p-sortIcon field="totalrecords"></p-sortIcon>
                        </th>
                        <th pSortableColumn="cleanrecords">
                           Clean Records 
                           <p-sortIcon field="cleanrecords"></p-sortIcon>
                        </th>
                        <th pSortableColumn="errorrecords">
                           Error Records 
                           <p-sortIcon field="errorrecords"></p-sortIcon>
                        </th>
                        <th pSortableColumn="queuedrecords">
                           Queued Records 
                           <p-sortIcon field="queuedrecords"></p-sortIcon>
                        </th>
                        <th pSortableColumn="queuedstartdate">
                           Queue Start Date 
                           <p-sortIcon field="queuedstartdate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="noofdaysinqueue" [hidden]="!jobProgress">
                           No Of Days in Queue
                           <p-sortIcon field="noofdaysinqueue"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estdate" [hidden]="!jobProgress">
                           Est Date 
                           <p-sortIcon field="estdate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="daystakentocompleted" [hidden]="jobProgress">
                           Days Taken To Complete
                           <p-sortIcon field="daystakentocompleted"></p-sortIcon>
                        </th>
                        <th pSortableColumn="datecompleted" [hidden]="jobProgress">
                           Date Completed
                           <p-sortIcon field="datecompleted"></p-sortIcon>
                        </th>
                        <th pSortableColumn="avg_agg_pd">
                           Agg Avg Per Day 
                           <p-sortIcon field="avg_agg_pd"></p-sortIcon>
                        </th>
                        <th pSortableColumn="agg_completed">
                           Agg Completed 
                           <p-sortIcon field="agg_completed"></p-sortIcon>
                        </th>
                        <th pSortableColumn="parsed">
                           Parsed Count
                           <p-sortIcon field="parsed"></p-sortIcon>
                        </th>
                        <th pSortableColumn="agg_error">
                           Agg Error
                           <p-sortIcon field="agg_error"></p-sortIcon>
                        </th>
                        <th pSortableColumn="NR">
                           NR
                           <p-sortIcon field="NR"></p-sortIcon>
                        </th>
                        <th pSortableColumn="inaggQueue">
                           In Agg Queue
                           <p-sortIcon field="inaggQueue"></p-sortIcon>
                        </th>
                        <th pSortableColumn="noof_cycles">
                           Queue Cycles
                           <p-sortIcon field="noof_cycles"></p-sortIcon>
                        </th>
                        <th pSortableColumn="parsed_error">
                           Parser Error
                           <p-sortIcon field="parsed_error"></p-sortIcon>
                        </th>
                        <th pSortableColumn="totalAgg">
                           Total Agg Count
                           <p-sortIcon field="totalAgg"></p-sortIcon>
                        </th>
                        <th pSortableColumn="exported">
                           Exported
                           <p-sortIcon field="exported"></p-sortIcon>
                        </th>
                        <th pSortableColumn="action">Action <p-sortIcon field="action"></p-sortIcon></th>
                     </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-jobs>
                     <tr class="p-selectable-row" (click)="renderRoundclick(jobs.jobid, jobs.jobname )" >
                        <td>
                           <span class="p-column-title" >Jobs Name</span>
                           {{jobs.jobname}}
                        </td>
                        <td>
                           <span class="p-column-title">Client</span>
                           {{jobs.client }} 
                        </td>
                        <td>
                           <span class="p-column-title">File Date</span>
                           {{jobs.filedate | date:'dd MMM YY' }}
                        </td>
                        <td>
                           <span class="p-column-title">No Of Files</span>
                           {{jobs.no_of_files | thousandSuff }}
                        </td>
                        <td>
                           <span class="p-column-title">No Of Records</span>
                           {{jobs.totalrecords  | thousandSuff }}
                        </td>
                        <td>
                           <span class="p-column-title">Clean Records</span>
                           {{jobs.cleanrecords  | thousandSuff  }}
                        </td>
                        <td>
                           <span class="p-column-title">Error Records</span>
                           {{jobs.errorrecords  | thousandSuff }}
                        </td>
                        <td>
                           <span class="p-column-title">Queued Records</span>
                           {{jobs.queuedrecords | thousandSuff }}
                        </td>
                        <td>
                           <span class="p-column-title">Queue Start Date</span>
                           {{jobs.queuestartdate  | date:'dd MMM YY'}}
                        </td>
                        <td [hidden]="!jobProgress">
                           <span class="p-column-title">No Of Days In Queue</span>
                           {{jobs.noofdaysinqueue}}
                        </td>
                        <td [hidden]="!jobProgress">
                           <span class="p-column-title">Est Date</span>
                           {{jobs.estdate  | date:'dd MMM YY'}}
                        </td>
                        <td [hidden]="jobProgress">
                           <span class="p-column-title">Days Taken To Complete</span>
                           {{jobs.daystakentocompleted}}
                        </td>
                        <td [hidden]="jobProgress">
                           <span class="p-column-title">Date Completed</span>
                           {{jobs.datecompleted  | date:'dd MMM YY'}}
                        </td>
                        <td>
                           <span class="p-column-title">Agg Avg Per Day</span>
                           {{jobs.avg_agg_pd  | thousandSuff }}
                        </td>
                        <td>
                           <span class="p-column-title">Agg Completed</span>
                           {{jobs.agg_completed | thousandSuff }} ( {{ jobs.agg_completed_percent }}% )
                        </td>
                        <td>
                           <span class="p-column-title">Parsed Count</span>
                           {{jobs.parsed | thousandSuff }}
                        </td>
                        <td>
                           <span class="p-column-title">Agg Error</span>
                           {{jobs.agg_error | thousandSuff }}
                        </td>
                        <td>
                           <span class="p-column-title">NR</span>
                           {{jobs.NR}}
                        </td>
                        <td>
                           <span class="p-column-title">In Agg Queue</span>
                           {{jobs.inaggQueue | thousandSuff }}
                        </td>    
                        <td>
                           <span class="p-column-title">Queue Cycles</span>
                           {{jobs.noof_cycles}}
                        </td>
                        <td>
                           <span class="p-column-title">Parser Error</span>
                           {{jobs.parsed_error | thousandSuff }}
                        </td>
                        <td>
                           <span class="p-column-title">Total Agg Count</span>
                           {{jobs.totalAgg | thousandSuff }} ( {{ jobs.totalAgg_percent }}% )
                        </td>
                        <td>
                           <span class="p-column-title">Exported</span>
                           {{jobs.exported | thousandSuff }}
                        </td>
                        <td style="text-align: center">
                            <!-- <a  routerLink="jobs" class="btn btn-outline-default" (click)="viewJobList($event)"><i class="fe fe-eye"></i></a> -->
                            <a class="btn btn-outline-default" (click)="viewJobList(jobs)"><i class="fe fe-eye"></i></a>
                        </td>
                     </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                     <div>
                        <div  class="text-center align-items-center justify-content-center d-flex" style="height: 310px;">No Data found.</div>
                     </div>
                  </ng-template>
                  </p-table>
               </div>
            </div>
         </div>
      </div>
   </div>
 </section>
 <section class="px-3">
   <div class="container-fluid">
      <div class="row mt-3 mb-3">
         <div class="col-6">
            <div class="row mt-3">
               <div class="col-12">
                  <div class="d-flex mb-2">
                     <h3 class="title"><span>{{clientFirst}}</span>{{clientRemaining}} - Round Trip View</h3></div>
               </div>
            </div>
            <div class="card p-0">
               <p-table #dr
               [value]="roundTrip" 
               dataKey="id" 
               styleClass="p-datatable-customers" 
               [loading]="loadingTable2"
               [rowHover]="true" 
               [rows]="10" 
               [showCurrentPageReport]="true" 
               [paginator]="true" 
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
               [filterDelay]="0"  
               [scrollable]="true" 
               scrollHeight="315px">
               <!-- <ng-template pTemplate="caption"><div class="table-header">
                  List of Customers
                  <span class="p-input-icon-left"><i class="pi pi-search"></i><input pInputText type="text" (input)="dt.filterGlobal($event.target, 'contains')" placeholder="Global Search" /></span></div></ng-template> -->
               <ng-template pTemplate="header">
                  <tr>
                     <th pSortableColumn="Queuedate">
                        Date 
                        <p-sortIcon field="Queuedate"></p-sortIcon>
                     </th>
                     <th pSortableColumn="jobstatus">
                        Job Status 
                        <p-sortIcon field="jobstatus"></p-sortIcon>
                     </th>
                     <th pSortableColumn="queuecount">
                        Queue Count
                        <p-sortIcon field="queuecount"></p-sortIcon>
                     </th>
                     <th pSortableColumn="agg">
                        Agg
                        <p-sortIcon field="agg"></p-sortIcon>
                     </th>
                     <th pSortableColumn="aggError">
                        Agg Error
                        <p-sortIcon field="aggError"></p-sortIcon>
                     </th>
                     <th pSortableColumn="requeue">
                        Requeue 
                        <p-sortIcon field="requeue"></p-sortIcon>
                     </th>
                     <th pSortableColumn="queueAchived">
                        Queue achieved
                        <p-sortIcon field="queueAchived"></p-sortIcon>
                     </th>
                  </tr>
               </ng-template>
               <ng-template pTemplate="body" let-jobs>
                  <tr class="p-selectable-row">
                     <td>
                        <span class="p-column-title">Date</span>
                        {{jobs.Queuedate | date:'dd MMM YY' }}
                     </td>
                     <td >
                        <span class="p-column-title">Job Status</span>
                        <button [ngClass]="jobs.jobstatus == 'inprogress' ? 'inprogress-class btn btn-sm' : 'completed-class btn-sm btn'">{{jobs.jobstatus }}</button>
                        
                     </td>
                     <td>
                        <span class="p-column-title">Queue Count</span>
                        {{jobs.queuecount | thousandSuff }}
                     </td>
                     <td>
                        <span class="p-column-title">Agg</span>
                        {{jobs.agg | thousandSuff }}
                     </td>
                     <td>
                        <span class="p-column-title">Agg Error</span>
                        {{jobs.aggError | thousandSuff }}
                     </td>
                     <td>
                        <span class="p-column-title">Requeue</span>
                        {{jobs.requeue | thousandSuff}}
                     </td>
                     <td>
                        <span class="p-column-title">Queue achieved</span>
                        {{jobs.queueAchived}}%
                     </td>
                  </tr>
               </ng-template>
               <ng-template pTemplate="emptymessage">
                  <div>
                     <div  class="text-center align-items-center justify-content-center d-flex" style="height: 310px;">No Data found.</div>
                  </div>
               </ng-template>
               </p-table>
            </div>
         </div>
         <div class="col-6">
            <div class="row mt-3">
               <div class="col-12">
                  <div class="row mb-2 filter">
                     <h3 class="title col-5"><span>B</span>usiness Attribute fill rate by job</h3>
                     <form class="form-row align-items-center justifiy-content-center col-7 filter-margin">
                        <div class="col-4 ml-4">
                           <div class="form-group">
                              <ng-select placeholder="Select Client" (change)="searchChart($event,'client')" [(ngModel)]="clientChart" [items]="client" 
                              bindLabel="provider" (clear)="clearClientJob('jobChart')" name="clientChart" id="clientChart" 
                              > </ng-select>
                           </div>
                        </div>
                        <div class="col-7">
                           <div class="form-group mb-0">
                              <ng-select placeholder="Select Job" class="inputjob-margin"
                              id="jobChartid" [clearable]="true" [multiple]="true" [closeOnSelect]="false" name="jobChartname" [items]="baJobsList" [(ngModel)]="jobChartname"  (change)="searchChart($event,'job')" bindLabel="jobname"  
                              >
                                 <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items | slice:0:1">
                                       <span class="ng-value-label">{{item.jobname}}</span>
                                       <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">x</span>
                                    </div>
                                    <div class="ng-value" *ngIf="items.length > 1">
                                       <span class="ng-value-label">{{items.length - 1}} more...</span>
                                    </div>
                                 </ng-template>
                              </ng-select>
                           </div>
                        </div>
                     </form>
                  </div>
                     
                     
                  <!-- <div class="row mb-2 filter">
                     <h3 class="title col-5"><span>B</span>usiness Attribute fill rate by job</h3>
                     <form class="form-row ml-auto align-items-center justifiy-content-center col-7">
                        <div class="col-6">
                           <div class="form-group">
                              <ng-select  placeholder="Select Client" (change)="searchChart($event,'client')"  [(ngModel)]="clientChart"   [items]="client" 
                              bindLabel="provider" (clear)="clearClientJob('jobChart')" name="clientChart" id="clientChart" 
                              > </ng-select>
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="form-group mb-0">
                              <ng-select 
                              placeholder="Select Job"
                              id="jobChartid" 
                              [clearable]="true" 
                              name="jobChartname" 
                              [items]="baJobsList" 
                              [(ngModel)]="jobChartname"  
                              (change)="searchChart($event,'job')" 
                              bindLabel="jobname"	
                              ></ng-select>
                           </div>
                        </div>
                     </form>
                  </div> -->
               </div>
            </div>
            <div class="card p-2">
               <div id="lineChart" style="width:100%; height:400px;"></div>
            </div>
         </div>
      </div>
   </div>
 </section>