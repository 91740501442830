import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AwsCognitoService {
  private env:any = {};

  constructor( private http: HttpClient ) {
    this.env = environment;
   }
  
  public getTokenDetailsFromCognito(callbackCode: string): Observable<any> {
    const details = {
      grant_type    : 'authorization_code',
      code          : callbackCode,
      scope         : 'openid+profile',
      redirect_uri  : this.env.cognitoSSO.redirectUrl
    };
    var formBody = "";
    for (const [key, value] of Object.entries(details)) {
      if(formBody!="")formBody += "&";
      formBody += encodeURIComponent(key)+"="+encodeURIComponent(value);
    }
    return this.http.post<any>(this.env.cognitoSSO.ssoTokenUrl,formBody, {
        responseType: 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic ' + btoa(`${this.env.cognitoSSO.clientId}:${this.env.cognitoSSO.secretKey}`)
        })
    });
  }

  public logoutUserFromCognito(): Observable<any> {
    return this.http.get<any>(this.env.cognitoSSO.logout);
  }

  public logoutUser(): void{
    let e = environment.cognitoSSO;
    let rUrl = e.ssoDomainUrl+'/logout?' +'client_id='+e.clientId+'& logout_uri='+e.siteLogoutUrl;
    window.location.assign(rUrl);
  }

  public loginUser(): void {
    let e = environment.cognitoSSO;
    let rUrl = e.ssoDomainUrl+'/login?' +'client_id='+e.clientId+'&response_type=code&scope=email+openid+phone+profile&redirect_uri='+e.redirectUrl;
    window.location.assign(rUrl);
  }

  public decodeToken(token:string):any {
    var tokenDetails:any = {};
    if(token){
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      tokenDetails = JSON.parse(atob(base64));
    } 
    return tokenDetails;
  }

  // public getUserInfo(code:any): void {
  //   let userInfo = null;
  //   if (code) {
  //     userInfo = this.getTokenDetailsFromCognito(code);
  //   }
  //   console.log(" getUserInfo >> ",userInfo);
  // }

}