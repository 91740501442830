import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

import { BsModal } from '@app/_models/bsmodal';

@Injectable({ providedIn: 'root' })
export class ModalService {

    private subject:Subject<BsModal>;
    private subjectModal: Observable<BsModal>;
    private defaultId:string = uuidv4();

    constructor() {
        this.subject        = new Subject<BsModal>();
        this.subjectModal   = this.subject.asObservable();
    }

    public get Modal():any {
        return this.subjectModal
    }

    onModal(id = this.defaultId): Observable<BsModal> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    clear(id = this.defaultId) {
        this.subject.next(new BsModal({ id }));
    }

}