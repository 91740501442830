import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	
    constructor( 
        private router: Router,
        private accountService: AccountService 
    ) {
       
    }
	
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.accountService.userValue;
        // return true;
        if(['/'].includes(state.url.toLowerCase())){
            return true;
        }else if (user && user.isAuthendicate) {            
            return true;
        }else{           
            this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
            return false;
        } 
    }
	
}