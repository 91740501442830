import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/*-------------------------------------------------------*/
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
/*-------------------------------------------------------*/
import { AccountService } from './account.service';
import { AlertService } from './alert.service';
import { LoaderService } from './loader-service';
import { UtilService } from './util.service';
/*-------------------------------------------------------*/
import { EnvService } from './env.service';
import { SettingsService } from './settings.service';
import { GlobalVars } from './global.service';


@Injectable({ providedIn: 'root' })
export class appService { 

  constructor(
    private routerService   : Router,
    private cookieService   : CookieService,
    private toasterService  : ToastrService,
    private accountService  : AccountService,
    private settingService  : SettingsService,
    private alertService    : AlertService,
    private loaderService   : LoaderService,
    private utilService     : UtilService,
    private globalVar       : GlobalVars,
    private envService      : EnvService
  ){} 

  public get router(): Router {
    return this.routerService;
  }

  public get cookie(): CookieService {
      return this.cookieService;
  }

  public get account(): AccountService {
    return this.accountService;
  }

  public get settings(): SettingsService {
    return this.settingService;
  }

  public get alert(): AlertService {
    return this.alertService;    
  }

  public get loader(): LoaderService {
    return this.loaderService;
  }

  public get util(): UtilService {
    return this.utilService;
  }

  public get env(): any {
    return this.envService.env;
  }

  public get global(): GlobalVars {
    return this.globalVar;
  }

  public get toaster(): ToastrService {
    let config:any = this.settingService.settings?.toaster || {};
    
    if(config && config.hasOwnProperty('autoDismiss')){
      this.toasterService.toastrConfig.autoDismiss = config.autoDismiss;
    }    
    if(config && config.hasOwnProperty('closeButton')){
      this.toasterService.toastrConfig.closeButton = config.closeButton;
    }    
    if(config && config.hasOwnProperty('countDuplicates')){
      this.toasterService.toastrConfig.countDuplicates = config.countDuplicates;
    }
    if(config && config.hasOwnProperty('disableTimeOut')){
      this.toasterService.toastrConfig.disableTimeOut = config.disableTimeOut;
    }
    if(config && config.hasOwnProperty('easeTime')){
      this.toasterService.toastrConfig.easeTime = config.easeTime;
    }
    if(config && config.hasOwnProperty('easing')){
      this.toasterService.toastrConfig.easing = config.easing;
    }
    if(config && config.hasOwnProperty('enableHtml')){
      this.toasterService.toastrConfig.enableHtml = config.enableHtml;
    }
    if(config && config.hasOwnProperty('extendedTimeOut')){
      this.toasterService.toastrConfig.extendedTimeOut = config.extendedTimeOut;
    }
    if(config && config.hasOwnProperty('iconClasses')){
      this.toasterService.toastrConfig.iconClasses = config.iconClasses;
    }    
    if(config && config.hasOwnProperty('newestOnTop')){
      this.toasterService.toastrConfig.newestOnTop = config.newestOnTop;
    }    
    if(config && config.hasOwnProperty('timeOut')){
      this.toasterService.toastrConfig.timeOut = config.timeOut;
    }   
    if(config && config.hasOwnProperty('positionClass')){
      this.toasterService.toastrConfig.positionClass = config.positionClass;
    }
    return this.toasterService;
  }

  public get emoji():any{
    return this.emojis;
  }

  private emojis:any = {
    'flower-1' : '🌻',
    'flower-2' : '🌺',
    'animal-1' : '🐳',
    'human-1'  : '🐶'
  }

}